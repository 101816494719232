export enum ImageProvider {
  facebook = 'facebook',
  instagram = 'instagram',
  local = 'local',
}

export type Image = {
  key?: string;
  provider: ImageProvider;
  url: string;
  height: number;
  width: number;
};

export type User = {
  id: string;
  name: string;
  email: string;
  bio?: string;
  images: Image[];
  artistIds: Set<string>;
  answers: any[];
  location: {
    latitude: number;
    longitude: number;
  };
};