/* External dependencies */
import React from 'react';

/* Internal dependencies */
import './MediaObject.scss';

type OwnProps = {
  text: React.ReactNode;
  subtext?: string;
  image: React.ReactNode;
  imageType?: 'rounded' | 'circle' | 'square';
  imageSize?: number;
  right?: React.ReactNode;
};

type Props = OwnProps;

export const DEFAULT_IMAGE_SIZE = 75;

const MediaObject: React.SFC<Props> = ({ text, subtext, image, imageType = 'rounded', imageSize = DEFAULT_IMAGE_SIZE, right }) => {
  const imageStyles: any = {
    height: imageSize,
    width: imageSize,
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: imageType === 'rounded' ? 10 : imageType === 'square' ? 0 : imageSize / 2,
  };

  return (
    <div className="bm-MediaObject">
      <div className="d-flex align-items-center" style={{ maxWidth: '100%' }}>
        <div className="bm-MediaObject__imageContainer">
          {typeof image === 'string' ? <img
            alt={typeof text === 'string' ? text : subtext || ''}
            src={image}
            style={imageStyles}
          /> : <div style={imageStyles}>{image}</div>}
        </div>
        <div className="bm-MediaObject__textContainer d-flex flex-wrap">
          {typeof text === 'string' ? <h6 className="bm-MediaObject_text d-flex flex-wrap">{text}</h6> : text}
          {Boolean(subtext) && (<h6 className="bm-MediaObject_text bm-MediaObject__subtext d-flex flex-wrap">{subtext}</h6>)}
        </div>
      </div>
      <div>
        {right}
      </div>
    </div>
  );
};

export default MediaObject;