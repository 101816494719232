/* External dependencies */
import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';

/* Internal dependencies */
import SubscribeModal from './SubscribeModal';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { ApplicationState } from 'src/store';
import { logEvent } from '@firebase/analytics';
import { analytics } from 'src/firebase';

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = StateProps;

type State = {
  showExitModal: boolean;
};

class ExitModal extends React.Component<Props, State> {
  state = { showExitModal: false };

  componentDidMount() {
    setTimeout(() => {
      window.document.addEventListener('mouseout', this.onMouseOut);
    }, 10000);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseout', this.onMouseOut);
  }

  onMouseOut = (e: MouseEvent) => {
    const { currentUser } = this.props;
    const exitModalOpened = Cookies.get('exitModalOpened');
    
    if (!currentUser && !exitModalOpened && !(e as any).toElement && !e.relatedTarget && e.clientY < 10) {
      this.setState({ showExitModal: true });
      Cookies.set('exitModalOpened', 'true', { expires: 30 });
      logEvent(analytics, 'waitlist_openExitIntentModal', this.state);
    }
  }

  handleHide = () => {
    this.setState({ showExitModal: false });
  }

  render() {
    const { showExitModal } = this.state;
    return <SubscribeModal show={showExitModal} onHide={this.handleHide} isExitModal={true} />;
  }
}

const mapStateToProps = (state : ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(ExitModal);