/* External dependencies */
import React, { FormEvent } from 'react';
import { Form, Modal } from 'react-bootstrap';

/* Internal dependencies */
import { addPrompt, listPrompts } from '../api/prompts';
import MediaObject from '../mediaObject/MediaObject';
import Spinner from '../spinner/Spinner';
import Button from '../button/Button';
import { ReactComponent as Microphone } from '../assets/images/microphone.svg';
import { ReactComponent as MusicalNote } from '../assets/images/musical-note.svg';

enum ModalId {
  addPrompt = 'addPrompt',
  editPrompt = 'editPrompt',
  disablePrompt = 'disablePrompt',
}

enum PromptType {
  track = 'track',
  artist = 'artist',
  album = 'album',
}

type State = {
  prompts: any[];
  loading: boolean;
  modalId: ModalId | undefined;
  title?: string;
  description?: string;
  promptType: PromptType;
  selectedPromptId?: string;
};

class ListPrompts extends React.Component<{}, State> {
  state = {
    prompts: [],
    loading: true,
    modalId: undefined,
    title: undefined,
    description: undefined,
    promptType: PromptType.track,
    selectedPromptId: undefined,
  };

  async componentDidMount() {
    try {
      const { prompts = []} = await listPrompts();
      this.setState({ prompts });
    } catch (e) {
      console.log('e', e);
    } finally {
      this.setState({ loading: false });
    }
  }

  showModal = (modalId: ModalId) => { this.setState({ modalId }); };

  hideModal = () => {
    this.setState({ modalId: undefined, selectedPromptId: undefined, title: undefined, description: undefined, promptType: PromptType.track });
  };

  addPrompt = async (e: FormEvent) => {
    const { title, description, promptType } = this.state;
    e.preventDefault();
    const prompt = await addPrompt({ title, description, promptType });
    this.setState(({ prompts }) => ({ prompts: [prompt, ...prompts], modalId: undefined }));
  };

  editPrompt = async () => {
    const { title, description, promptType } = this.state;
  };

  renderAddPromptModal = () => {
    const { modalId, title, description, promptType } = this.state;

    return (
      <Modal size="lg" show={modalId === ModalId.addPrompt} onHide={this.hideModal}>
        <Form>
          <Modal.Header translate={false}closeButton>
            <Modal.Title>Add a Prompt</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column justfiy-content-center align-items-start">
            <div className="mb-3" style={{ width: '100%' }}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                autoFocus={true}
                onInput={(e: any) => { this.setState({ title: e.target.value }); }}
                style={{ width: '100%' }}
              />
            </div>
            <div className="mb-3" style={{ width: '100%' }}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Description"
                autoFocus={true}
                onInput={(e: any) => { this.setState({ description: e.target.value }); }}
                style={{ width: '100%' }}
              />
            </div>
            <div className="mb-3" style={{ width: '100%' }}>
              <Form.Label>Prompt Type</Form.Label>
              <div className="d-flex justify-content-start">
                <button type="button" className="p-2 rounded mr-3 bg-white" onClick={(e: any) => { this.setState({ promptType: PromptType.track }); }} style={{ border: '1px solid #ced4da' }}>
                  <Form.Check
                    type="radio"
                    label="Track"
                    autoFocus={true}
                    checked={promptType === PromptType.track}
                    style={{ width: '100%' }}
                  />
                </button>
                <button type="button" className="p-2 rounded bg-white" onClick={() => { this.setState({ promptType: PromptType.artist }); }} style={{ border: '1px solid #ced4da' }}>
                  <Form.Check
                    type="radio"
                    label="Artist"
                    placeholder="Type of prompt..."
                    autoFocus={true}
                    checked={promptType === PromptType.artist}
                    style={{ width: '100%' }}
                  />
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={this.hideModal}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={this.addPrompt} disabled={!title || !description || !promptType}>
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  render() {
    const { prompts, loading } = this.state;

    return (
      <>
        {this.renderAddPromptModal()}
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            <Form className="mr-3" style={{ flex: 1 }}>
              <div className="mb-3" style={{ width: '100%' }}>
                <Form.Control
                  type="text"
                  placeholder="Search Prompts..."
                  autoFocus={true}
                  onInput={(e: any) => {}}
                  style={{ width: '100%' }}
                />
              </div>
            </Form>
            <div>
              <Button
                onClick={() => {
                  this.setState({
                    modalId: ModalId.addPrompt,
                    title: undefined,
                    description: undefined,
                    promptType: PromptType.track,
                  });
                }}
              >
                Add
              </Button>
            </div>
          </div>
        {loading ? <Spinner /> : prompts.map(({ id, title, description, promptType }) => (
            <div key={id} className="mb-4 p-3 bg-white rounded" style={{ border: '0.5px solid #e2e2e2', borderRadius: 20, width: '100%' }}>
              <MediaObject
                text={title}
                subtext={description}
                imageType="circle"
                image={(
                  <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f7f7f7', height: '100%', width: '100%' }}>
                    {promptType === 'track' && <MusicalNote width={30} style={{ padding: 0, margin: 0 }} />}
                    {promptType === 'artist' && <Microphone width={40} style={{ padding: 0, margin: 0 }} />}
                  </div>
                )}
                right={(
                  <div className="d-flex">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        this.setState({
                          modalId: ModalId.editPrompt,
                          title,
                          description,
                          promptType,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default ListPrompts;