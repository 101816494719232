
/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';

export const getMyRecentlyPlayedTracks = async (userId: string, limit: number = 25) =>
  await API.get(ApiName.bmApp, `/spotify/getMyRecentlyPlayedTracks`, {
    queryStringParameters: { userId, limit }, 
  });

export const getMySavedTracks = async (limit: number = 25, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/spotify/getMySavedTracks`, {
    queryStringParameters: { limit, offset },
  });

export const getMyTopTracks = async (limit: number = 25, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/spotify/getMyTopTracks`, {
    queryStringParameters: { limit, offset },
  });

export const getMyTopArtists = async (limit: number = 25, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/spotify/getMyTopArtists`, {
    queryStringParameters: { limit, offset },
  });

export const getUserPlaylists = async (userId: string, limit: number = 25, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/spotify/getUserPlaylists`, {
    queryStringParameters: { userId, limit, offset },
  });

export const getPlaylistsForSpotifyUser = async (userId: string, limit: number = 25, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/spotify/getPlaylistsForSpotifyUser`, {
    queryStringParameters: { userId, limit, offset },
  });

export const getPlaylistTracks = async (playlistId: string, limit: number = 25, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/spotify/getPlaylistTracks`, {
    queryStringParameters: { playlistId, limit, offset },
  });

export const getRecommendations = async ({ limit = 25, seed_tracks = [], seed_artists = [], seed_genres = [] }) =>
  await API.get(ApiName.bmApp, `/spotify/getRecommendations`, {
    queryStringParameters: {
      limit,
      seed_tracks: seed_tracks.join(','),
      seed_artists: seed_artists.join(','),
      seed_genres: seed_genres.join(','),
    },
  });

export const addTrackToBeatmatchPlaylist = async (providerId: string) =>
  await API.post(ApiName.bmApp, '/spotify/addTrackToBeatmatchPlaylist', {
    body: { providerId }
  });