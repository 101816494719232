/* External dependencies */
import { API } from 'aws-amplify';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { User, Image } from '../types/User';


export const getUser = async (userId: string): Promise<User> => await API.get(ApiName.bmApp, `/users/${userId}`, null);

export const addImageForUser = async (userId: string, image: Image) =>
  await API.post(ApiName.bmApp, `/users/${userId}/images`, {
    body: image,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
  });

export const addImageForUser2 = async (userId: string, image: Omit<Image, 'url'>) =>
  await API.post(ApiName.bmApp, `/users/${userId}/images2`, {
    body: image,
  });

export const listUsers = async ({ limit = 15, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users`, {
    queryStringParameters: cleanObject({
      limit,
      offset: Boolean(offset) && JSON.stringify(offset) || undefined,
    }),
  });

export const searchUsers = async (q: string, { limit = 15, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users`, {
    queryStringParameters: cleanObject({
      q,
      limit,
      offset: Boolean(offset) && JSON.stringify(offset) || undefined,
    }),
  });

export const createUserSubscription = async ({ email, firstName, lastName, referral_link }: { email: string, firstName?: string, lastName?: string, referral_link?: string }) =>
  await API.post(ApiName.bmApp, '/users/subscriptions', {
    body: { email, firstName, lastName, referral_link },
  });

export const updateUserSubscription = async ({ email, firstName, lastName }: { email: string, firstName?: string, lastName?: string }) =>
  await API.put(ApiName.bmApp, `/users/subscriptions/${email}`, {
    body: { email, firstName, lastName },
  });