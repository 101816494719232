/* External dependencies */
import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

/* Internal dependencies */
import { listUsers, searchUsers } from '../api/users';
import MediaObject from '../mediaObject/MediaObject';
import { User } from '../types/User';
import Spinner from '../spinner/Spinner';
import Button from '../button/Button';

type State = {
  users: User[];
  loading: boolean;
};

class ListUsers extends React.Component<{}, State> {
  state = { users: [], loading: true };

  async componentDidMount() {
    try {
      const { items: users } = await listUsers({ limit: 15 });
      this.setState({ users });
    } catch (e) {
      console.log('e', e);
    } finally {
      this.setState({ loading: false });
    }
  }

  searchUsers = async (q: string) => {
    this.setState({ loading: true });
    try {
      const { items: users } = await searchUsers(q, { limit: 15 });
      this.setState({ users });
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { users, loading } = this.state;

    return (
      <div className="d-flex flex-column align-items-center">
        <Form style={{ width: '100%' }}>
          <div className="mb-3" style={{ width: '100%' }}>
            <Form.Control type="text"
              placeholder="Search Users..."
              autoFocus={true}
              onInput={(e: any) => { this.searchUsers(e.target.value); }}
              style={{ width: '100%' }}
            />
          </div>
        </Form>
        {loading ? <Spinner /> : users.map(({ id, name, images = [] }) => (
          <div key={id} className="mb-4 p-3 bg-white rounded" style={{ border: '0.5px solid #e2e2e2', borderRadius: 20, width: '100%' }}>
            <MediaObject
              text={<Link to={`/admin/users/${id}`}>{name}</Link>}
              subtext={id}
              image={get(images, '[0].url')}
              imageType="circle"
              right={(
                <div className="d-flex">
                  <Link to={`/admin/users/${id}`} className="btn btn-primary mr-3">View</Link>
                  <Button variant="secondary">Edit</Button>
                </div>
              )}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default ListUsers;