/* External dependencies */
import React from 'react'
import sanitize from 'sanitize-html';

type OwnProps = {
  html: string;
  className?: string;
  style?: Object;
};

type Props = OwnProps;

const Sanitize: React.SFC<Props> = ({ html = '', className = '', style = {} }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitize(html, {
        allowedTags: ['p','div','img','b','i','ul','li','h1','h2','h3','h4','h5','h6','iframe','a'],
        allowedAttributes: {
          '*': ['*'],
        },
      })}}
      style={style}
    />
  );
}

export default Sanitize;