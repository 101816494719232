/* External dependencies */
import React from 'react';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import Subscribe from 'src/subscribe/Subscribe';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="bm-Footer d-flex flex-column justify-content-center align-items-center p-4">
      <div className="bm-Footer__container d-flex justify-content-between align-items-center flex-wrap container" style={{ width: '100%' }}>
        <Link to="/">
          <Logo fill="#fff" width="40%" style={{ width: 200 }} />
        </Link>
        <div className="bm-Footer_subscribe">
          <Subscribe showLabel={false} />
        </div>
        <div>
          <a aria-label="Visit our Instagram" className="bm-Footer__social_link mr-4" href="https://instagram.com/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram" style={{ fontSize: 28 }} />
          </a>
          <a aria-label="Visit our TikTok" className="bm-Footer__social_link mr-4" href="https://www.tiktok.com/@beatmatch" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-tiktok" style={{ fontSize: 25 }} />
          </a>
          <a aria-label="Visit our Facebook" className="bm-Footer__social_link mr-4" href="https://facebook.com/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook" style={{ fontSize: 28 }} />
          </a>
          <a aria-label="Visit our Twitter" className="bm-Footer__social_link mr-4" href="https://twitter.com/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter" style={{ fontSize: 28 }} />
          </a>
          <a aria-label="Visit our YouTube" className="bm-Footer__social_link mr-4" href="https://www.youtube.com/channel/UC5-LaZHZ8faI85O2HvEOiTQ" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-youtube" style={{ fontSize: 28 }} />
          </a>
          <a aria-label="Visit our Spotify" className="bm-Footer__social_link mr-4" href="https://open.spotify.com/user/bakkvmm7qkhmy1nq5kk12naxh" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-spotify" style={{ fontSize: 28 }} />
          </a>
          <a aria-label="Visit our Twitch" className="bm-Footer__social_link" href="https://twitch.tv/beatmatchsocial" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitch" style={{ fontSize: 25 }} />
          </a>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-center">
        <p className="text-white mr-3">© {new Date().getFullYear()} Beatmatch | All Rights Reserved</p>
        <u><Link to="/terms-and-conditions" target="_blank" className="text-center mr-3">Terms and Conditions</Link></u>
        <u><Link to="/privacy-policy" target="_blank" className="text-center">Privacy Policy</Link></u>
      </div>
    </footer>
  );
};

export default Footer;