/* External dependencies */
import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import Lottie from 'react-lottie';

/* Internal dependencies */
import './Spinner.scss';


const WaveForm = require('../assets/animations/waveform.json');
const WaveFormWhite = require('../assets/animations/waveform-white.json');

type OwnProps = {
  variant?: 'light' | 'dark';
  playing?: boolean;
};

type Props = OwnProps;

const Spinner: React.SFC<Props> = ({ variant = 'dark', playing = true }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: variant === 'light' ? WaveFormWhite : WaveForm,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="bm-Spinner">
      {/* <BootstrapSpinner variant={variant} animation="border" role="status">
        <span className="sr-only text-white">Loading...</span>
      </BootstrapSpinner> */}
      <Lottie options={defaultOptions} height={100} width={100} isPaused={!playing} speed={0.4} />
    </div>
  );
};

export default Spinner;