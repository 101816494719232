// @ts-ignore
/* External dependencies */
import React from 'react';
import Helmet from 'react-helmet';

/* Internal dependencies */
import Navbar from 'src/navbar/Navbar';

const Div: React.SFC<any> = (props) => <div {...props}>{props.children}</div>;

const TermsAndConditions = () => {
  const pageTitle = 'Beatmatch | Terms and Conditions';
  const pageDescription = 'The Beatmatch terms and conditions';
  const pageUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/terms-and-conditions`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:site_name" content="Beatmatch" />
        <meta property="twitter:description" content={pageDescription} />
        <meta property="twitter:url" content={pageUrl} />
        <meta property="twitter:image:alt" content={pageTitle} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:card" content="summary" />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Navbar />
      <Div className="container pb-3" name="termly-embed" data-id="e35e0181-b35b-48b8-9606-0bba9539fd6e" data-type="iframe"></Div>
      {
        (function(d, s, id) {
          var js, tjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          (js as any).src = "https://app.termly.io/embed-policy.min.js";
          tjs!.parentNode!.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'))
      }
    </>
  );
};

export default TermsAndConditions;