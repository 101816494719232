/* External dependencies */
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { match } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

/* Internal dependencies */
import { getUser } from '../api/users';
import MediaObject from '../mediaObject/MediaObject';
import { User } from '../types/User';
import Spinner from '../spinner/Spinner';
import Button from '../button/Button';
import { modelUser } from '../store/helpers/users';
import AuthRoutes from '../AuthRoutes';
import ListMatches from './ListMatches';
import ListAnswers from './ListAnswers';

type InjectedProps = {
  match: match<{
    userId: string;
  }>;
};

type Props = InjectedProps;

type State = {
  user: User | undefined;
  loading: boolean;
};

class ViewUser extends React.Component<Props, State> {
  state = { user: undefined, loading: true };

  async componentDidMount() {
    await this.load();
  }

  componentDidUpdate({ match: { params } }: any) {
    const { userId } = params;

    if (userId !== this.props.match.params.userId) {
      this.load();
    }
  }

  load = async () => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;
      const { userId } = params;
      const user = await getUser(userId);
      const { location: { longitude, latitude } = {} } = user;
      let city: string = '';
      let state: string = '';
      if (latitude && longitude) {
        const { results } = await (await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDqICRSL-VyCo6a_ttofhJZpyJGnyP3ryM`)).json();
        const [result] = results;
        const { address_components = [] } = result;
        const { long_name: city_long_name = '' } = (address_components.find(({ types = [] as string[] }) => types.includes('locality')) || {});
        const { short_name: state_short_name = '' } = (address_components.find(({ types = [] as string[] }) => types.includes('administrative_area_level_1')) || {});
        city = city_long_name;
        state = state_short_name;
      }

      this.setState({ user: { ...modelUser(user), city, state } });
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { user = {}, loading } = this.state;

    if (!(user as any).id && !loading) return null;

    const { id, name, images = [], birthday, gender, email, phone, city, state, testUser = false } = user! as any;
    const bDay = moment(birthday);
    const age = moment().diff(bDay, 'years');

    return (
      <AuthRoutes showNavbar={true} alwaysFillNavbar={true}>
        {loading ? <Spinner /> : (
          <div className="d-flex flex-column align-items-center">
            <div className="mb-5 p-5 bg-white rounded" style={{ border: '0.5px solid #e2e2e2', borderTop: 'none', borderRadius: 20, width: '100%' }}>
              <div className="container">
                <MediaObject
                  text={<h2>{name}</h2>}
                  subtext={id}
                  image={get(images, '[0].url')}
                  imageType="circle"
                  imageSize={200}
                  right={(
                    <div className="d-flex">
                      <Button variant="secondary" className="mr-3">Edit</Button>
                      <Button disabled={true} variant="warning" className="mr-3">Suspend</Button>
                      <Button disabled={true} variant="danger">Delete</Button>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="container">
              <Tabs id="tabs">
                <Tab key="info" eventKey="info" title="Info">
                  <div className="bg-white p-3 rounder" style={{ border: '0.5px solid #e2e2e2', borderRadius: 20, width: '100%' }}>
                    <div className="d-flex justify-content-between flex-wrap">
                      {/* <div>
                        <h6 className="text-bold">ID</h6>
                        <p>{id}</p>
                      </div> */}
                      <div>
                        <h6 className="text-bold">Gender</h6>
                        <p>{gender}</p>
                      </div>
                      <div>
                        <h6 className="text-bold">Age</h6>
                        <p>{age}</p>
                      </div>
                      <div>
                        <h6 className="text-bold">Location</h6>
                        <p>{city}, {state}</p>
                      </div>
                      <div>
                        <h6 className="text-bold">Email</h6>
                        <p>{email || 'N/A'}</p>
                      </div>
                      <div>
                        <h6 className="text-bold">Phone Number</h6>
                        <p>{phone}</p>
                      </div>
                      <div>
                        <h6 className="text-bold">Test User</h6>
                        <p>{testUser ? 'Yes' : 'No'}</p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab key="answers" eventKey="answers" title="Answers">
                  <ListAnswers userId={id} />
                </Tab>
                <Tab key="matches" eventKey="matches" title="Matches">
                  <ListMatches userId={id} />
                </Tab>
                <Tab key="Likes" eventKey="likes" title="Likes">

                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </AuthRoutes>
    );
  }
}

export default ViewUser;