/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cacheify } from '../helpers/cacheify';
import { PaginationOptions } from '../types/Pagination';

const cachedSearchTracks = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(async (q: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, '/search', {
    queryStringParameters: {
      q,
      type: 'track',
      limit,
      offset,
    },
  }), 60000);

export const searchTracks = async (q: string, options: PaginationOptions) => await cachedSearchTracks()(q, options);