/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cacheify } from '../helpers/cacheify';
import { PaginationOptions } from '../types/Pagination';

export const addArtistForUser = async (artist: any, userId: string) =>
  await API.post(ApiName.bmApp, `/users/${userId}/artists`, {
    body: artist,
  });

export const removeArtistForUser = async (artistId: string, userId: string) =>
  await API.del(ApiName.bmApp, `/users/${userId}/artists/${artistId}`, null);

export const listArtistsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users/${userId}/artists`, {
    queryStringParameters: {
      limit,
      offset: JSON.stringify(offset),
    },
  });

export const getArtists = async (artistIds: string[]) =>
  await API.get(ApiName.bmApp, `/artists/getArtists`, {
    queryStringParameters: {
      artistIds: artistIds.join(','),
    },
  });

const cachedSearchArtists = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(async (q: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, '/search', {
    queryStringParameters: {
      q,
      type: 'artist',
      limit,
      offset,
    },
  }), 60000);

export const searchArtists = async (q: string, options: PaginationOptions) => await cachedSearchArtists()(q, options);