/* External dependencies */
import stringify from 'json-stable-stringify';

type InvocationKey = string;
const createInvocationKey = <I>(input: I): InvocationKey => stringify(input);

export enum CacheOption {
  /** Use the cached response, if available. Only make a new call if a cached response is not available. */
  default,
  /** Flush any cached response, then call. */
  flushAndCall,
}

const invalidators = new Map<(...args: any[]) => void, () => void>();

/** Non-caching cacheify implementation used in jest testing environment. */
const fakeCacheify = <F extends (...args: any[]) => O | Promise<O>, O>(fn: F, _: number = 0) => {
  return (_: CacheOption = CacheOption.default) => async (...args: any[]): Promise<O>  => fn(...args);
};

/** Production cacheify implementation. */
const realCacheify = <F extends (...args: any[]) => O | Promise<O>, O>(fn: F, duration: number = 5000) => {
  const results = new Map<InvocationKey, O | Promise<O>>();

  if (!invalidators.has(fn)) {
    invalidators.set(fn, () => results.clear());
  }

  const setCache = (invocationKey: InvocationKey, promise: O | Promise<O>) => {
    results.set(invocationKey, promise);
  };

  const getCache = (invocationKey: InvocationKey) => results.get(invocationKey);

  const flushCache = (invocationKey: InvocationKey) => results.delete(invocationKey);

  return (cacheOption: CacheOption = CacheOption.default) => async (...args: any[]): Promise<O> => {
    const invocationKey = createInvocationKey(args);
    if (cacheOption === CacheOption.flushAndCall) {
      flushCache(invocationKey);
    }

    if (!getCache(invocationKey)) {
      setCache(invocationKey, fn(...args));
      setTimeout(() => {
        flushCache(invocationKey);
      }, duration);
    }

    return getCache(invocationKey)!;
  };
};

/**
 * Utility helper to handle caching for API clients.
 * Cacheify utilizes a closure to store usage information about the client in order to enable a clean caching solution.
 * A cache config can be passed to configure the cache settings
 *
 * @param fn
 * The function to be cached. This can be any function but will typically be used
 * for API clients or functions that return a Promise.
 * @param duration
 * Milliseconds to cache the response for.
 *  Once the cache expires a new response will be return on subsequent function invocations.
 * Default is 5000 milliseconds or 5 seconds.
 *
 * Example usage:
 *
 ```
  import asyncCodeDeploy from '@roc/...';

  const listApplications = cacheify(asyncCodeDeploy.listApplications, 10000 );
  yield call(listApplications); // cached for 10 seconds

  yield call(listApplications, {}, true); // flushes cache to force fetching fresh data
 ```
 */
export const cacheify = process.env.NODE_ENV === 'test' ? fakeCacheify : realCacheify;

export const flushCacheify = () => invalidators.forEach(invalidate => invalidate());

export const test = { cacheify: realCacheify };
