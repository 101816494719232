/* External dependencies */
import React from 'react';
import Helmet from 'react-helmet';
import { Link, match } from 'react-router-dom';
import { Form, Image, Modal } from 'react-bootstrap';
import shuffle from 'lodash/shuffle';
import confetti from 'canvas-confetti';

/* Internal dependencies */
import '../home/Home.scss';
import './Fandom.scss';
import Spotify from '../assets/images/spotify.svg';
import AppleMusic from '../assets/images/apple-music.svg';
import MusicLoverRadar from '../assets/images/music-lover-radar.png';
import { ReactComponent as BoltLightPurple } from '../assets/images/bolt-light-purple.svg';
import { ReactComponent as BoltWhite } from '../assets/images/bolt-white.svg';
import { addMemberForLaunchWaitlist, getLaunchWaitlist } from 'src/api/waitlists';
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import Button from 'src/button/Button';
import Stepper from 'src/stepper/Stepper';
import CheckCircle from 'src/checkCircle/CheckCircle';
import Item from 'src/item/Item';
import { getPlaylistTracks } from 'src/api/spotify';
import { connect } from 'react-redux';
import { addNotification } from 'src/store/ducks/notifications';
import { Dispatch } from 'redux';
import { Notification } from 'src/types/Notification';
import PostShareButtons from 'src/blog/PostShareButtons';
import { Member } from 'src/types/Member';
import Footer from 'src/footer/Footer';

type InjectedProps = any & {
  match: match<{
    userId: string;
  }>;
  history: any;
};

type OwnProps = {
  fandom: string;
  title: string;
  description: string;
  headerDescription: React.ReactNode;
  radarImage: React.ReactNode;
  radarTitle: React.ReactNode;
  artistName: string;
  firstSectionTitle: React.ReactNode;
  firstSectionDescription: React.ReactNode;
  firstSectionImage: React.ReactNode;
  secondSectionTitle: React.ReactNode;
  secondSectionDescription: React.ReactNode;
  secondSectionImage: React.ReactNode;
  firstBannerTitle: React.ReactNode;
  firstBannerDescription: React.ReactNode;
  secondBannerTitle: React.ReactNode;
  secondBannerDescription: React.ReactNode;
  successImage: string;
  slug: string;
};

type Props = InjectedProps & OwnProps;

type State = {
  currentSlideIndex: number;
  waitlistTotal: number | undefined;
  waitlistLoading: boolean;
  quizModal: boolean;
  activeStepIndex: number;
  selectedItemId: string | undefined;
  selectedItemIds: {
    [stepIndex: number]: string | undefined;
  };
  tracks: any[];
  firstName: string | undefined;
  email: string | undefined;
  member?: Member;
  loading: boolean;
  successModal: boolean;
};

class Fandom extends React.Component<Props, State> {
  images: string[];
  confetti: confetti.CreateTypes | undefined;

  constructor(props: any) {
    super(props);
    this.images = shuffle(props.waitlistImages);
    this.state = {
      currentSlideIndex: 0,
      waitlistTotal: undefined,
      waitlistLoading: true,
      quizModal: false,
      activeStepIndex: 0,
      selectedItemId: undefined,
      selectedItemIds: {},
      tracks: [],
      firstName: undefined,
      email: undefined,
      loading: false,
      successModal: false,
    };
  }

  async componentDidMount() {
    try {
      this.confetti = confetti.create(null as any, { resize: true });
      const [{ total }, { items: tracks }] = await Promise.all([
        getLaunchWaitlist(),
        this.loadArtistTracks(),
      ]);
      this.setState({ waitlistTotal: total, tracks: shuffle(tracks) });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ waitlistLoading: false });
    }
  }

  loadArtistTracks = async () => {
    const { playlistId } = this.props;
    return await getPlaylistTracks(playlistId, 40);
  };

  popConfetti = () => {
    if (this.confetti) {
      this.confetti({
        particleCount: 100,
        spread: 160,
        zIndex: 100000,
        colors: ['#9966ff', '#9966ff', '#fff', '#000'],
      });
    }
  };

  renderFeaturedSection = ({ title, description, image, backgroundColor = '#000', reverse = false, className, buttonText = 'GET STARTED' }: any) => {
    return (
      <section className={className} style={{ backgroundColor }}>
        <div className="container" style={{ height: '100%', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div data-aos="fade-in" className={`container d-flex ${reverse ? 'flex-row-reverse' : ''} justify-content-between align-items-center flex-wrap`} style={{ display: 'block', paddingBottom: '3rem', width: '100%' }}>
              <div className={`bm-Home__bannerImage bm-Home__bannerImage--large ${reverse ? 'bm-Home__bannerImage--reverse' : ''}`}>
                <Image src={image} width="100%" fluid />
              </div>
              <div data-aos="fade-in" className="bm-Home__bannerDescription">
                <h1 className="text-bold text-white mb-4">{title}</h1>
                <p className="text-white lead">{description}</p>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.setState({ quizModal: true });
                  }}
                  style={{ width: '100%', borderRadius: 30, fontWeight: 700, height: 150 }}
                >
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  renderBannerSection = ({ title, description, buttonText = 'FIND LOVE' }: any) => {
    return (
      <section className="d-flex flex-column align-items-center pt-5 pb-5" style={{ width: '100%', backgroundColor: '#C7ACFF' }}>
        <h2 data-aos="fade-in" style={{ color: '#000' }}>{title}</h2>
        <p data-aos="fade-in" className="lead" style={{ color: '#000' }}>{description}</p>
        <div data-aos="fade-in" style={{ width: '30%' }}>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ quizModal: true });
            }}
            style={{ width: '100%', borderRadius: 30, fontWeight: 700, height: 150, backgroundColor: '#000' }}
          >
            <span style={{ color: '#C7ACFF' }}>{buttonText}</span>
          </Button>
        </div>
      </section>
    );
  };


  handleAnswerCheck = (id: string, stepIndex: number) => {
    this.setState(({ selectedItemIds }) => ({
      selectedItemIds: {
        ...selectedItemIds,
        [stepIndex]: selectedItemIds[stepIndex] === id ? undefined : id,
      }
    }));
  };

  renderTrackOptions = (stepIndex: number, title: string, tracks: any[]) => {
    const { selectedItemIds } = this.state;

    return (
      <div>
        <div className="mb-3 p-3 rounded d-flex justify-content-between align-items-center">
          <h4 style={{ padding: 0, margin: 0 }}>{title}</h4>
        </div>
        <div>
          {tracks.map((item: any, i) => {
            const { id } = item;
            return (
              <button type="button" key={id} className="btn mb-3" onClick={() => { this.handleAnswerCheck(id, stepIndex); }} style={{ width: '100%' }}>
                <Item
                  item={item}
                  right={
                    <CheckCircle
                      id={id}
                      checked={id === selectedItemIds[stepIndex]}
                      onCheck={id => { this.handleAnswerCheck(id, stepIndex); }}
                    />
                  }
                />
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  handleJoin = async () => {
    const { addNotification } = this.props;
    const { email, firstName } = this.state;

    if (!email || !firstName) {
      addNotification({
        title: 'You skipped a beat!',
        message: 'You must enter your first name and email address to join the party.',
        variant: 'danger',
      });
      return;
    }

    try {
      this.setState({ loading: true });
      const urlParams = new URLSearchParams(window.location.search);
      const referralId = urlParams.get('ref_id') || undefined;
      const updatedMember = await addMemberForLaunchWaitlist({ email: email!, firstName, referralId });
      this.setState({ member: updatedMember });
      addNotification({
        title: `${firstName}, you\'re locked in!`,
        message: 'You\'re on the waitlist. Stay tuned to find out when a spot opens up!',
        variant: 'primary',
      });
      this.popConfetti();
      this.setState({ activeStepIndex: 0, quizModal: false, successModal: true });
    } catch (e) {
      console.log('error with user subscription', e);
      addNotification({
        title: 'Already On The Waitlist',
        message: 'You have already been added to the waitlist. Check your email for updates!',
        variant: 'danger',
      });
    } finally {
      this.setState({ loading: false });
    }
  };
  

  render() {
    const {
      fandom,
      title,
      description,
      headerBackgroundClass,
      headerDescription,
      headerButtonText,
      headerQuote,
      radarImage = MusicLoverRadar,
      radarTitle,
      radarButtonText,
      artistName,
      firstSectionTitle,
      firstSectionDescription,
      firstSectionImage,
      secondSectionTitle,
      secondSectionDescription,
      secondSectionImage,
      thirdSectionTitle,
      thirdSectionDescription,
      thirdSectionImage,
      firstBannerTitle,
      firstBannerDescription,
      firstBannerButtonText,
      secondBannerTitle,
      secondBannerDescription,
      secondBannerButtonText,
      successImage,
      productHuntBadge,
      slug,
    } = this.props;
    let { waitlistTotal, waitlistLoading, quizModal, activeStepIndex, tracks = [], selectedItemIds, firstName, email, loading, member, successModal } = this.state;
    let position: number = 0;

    if (member && member.position !== undefined && member.position !== null) {
      position = member.position;
    };

    const referralLink = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/${slug}?ref_id=${member && member!.referralId}`;
    let placeInLine = <span><span className="text-primary">{position > 0 ? position - 1 : 0}</span> {position === 2 ? 'person is' : 'people are'} ahead of you.</span>
    if (position === 1) {
      placeInLine = <span>You're <span className="text-primary">1st</span> in line!</span>;
    }
    const metaTitle = `Beatmatch | ${title}`;
    const steps = [
      {
        title: 'Question #1',
        content: this.renderTrackOptions(0, 'Which of these songs is best to dance to?', tracks.slice(0,3)),
      },
      {
        title: 'Question #2',
        content: this.renderTrackOptions(1, 'Which of these songs is best to workout to?', tracks.slice(3,6)),
      },
      {
        title: 'Question #3',
        content: this.renderTrackOptions(2, 'Which of these songs is best to study to?', tracks.slice(6,9)),
      },
      {
        title: 'Your Info',
        content: (
          <div className="pl-3 pr-3">
            <h3 className="mb-3">Find out if you qualify</h3>
            <div className="mb-3">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your first name"
                autoFocus={true}
                onInput={(e: any) => { this.setState({ firstName: e.target.value }); }}
              />
            </div>
            <div className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                onInput={(e: any) => { this.setState({ email: e.target.value }); }}
              />
            </div>
          </div>
        ),
      },
    ];

    return (
      <div className="bm-Fandom">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle}</title>
          <meta property="og:determiner" content="a" />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:image" content={`http://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/welcome-to-beatmatch.png`} />
          {/* <meta property="og:image:secure_url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
          <meta property="og:image:height" content="500" />
          <meta property="og:image:width" content="500" /> */}
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content={description} />
          <meta name="twitter:site" content="@beatmatchsocial" />
          <meta name="twitter:creator" content="@beatmatchsocial" />
          <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/welcome-to-beatmatch.png`} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:card" content="summary" />
          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <script type="application/ld+json">
            {
              `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}`}",
                "logo": "${`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`}"
              }`
            }
          </script>
          <script type="application/ld+json">
            {
              `{
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "Beatmatch",
                "operatingSystem": "IOS",
                "applicationCategory": "SocialNetworkingApplication",
                "applicationSubCategory": "DatingApplication"
              }`
            }
            </script>
          <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1328aa7a8f414acc4aaad2634/467d56da5fc744cb331e4ee25.js");`}</script>
        </Helmet>
        <section className={`bm-Fandom__header_image ${headerBackgroundClass ? headerBackgroundClass : ''} d-flex justify-content-center align-items-center`} style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
          <div className="d-none d-sm-none d-md-block d-lg-block" data-aos="fade-in" data-aos-duration="1750" style={{ marginLeft: '70%' }}>
            <BoltLightPurple stroke="#fff" opacity={0.85} />
          </div>
          <div className="d-none d-sm-none d-md-block d-lg-block" data-aos="fade-in" data-aos-duration="1750" style={{ marginLeft: '70%', position: 'absolute', left: 20 }}>
            <BoltWhite stroke="#fff" opacity={0.5} />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-5" style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
            <div data-aos="fade-in" className="container d-flex flex-column justify-content-start align-items-center text-center">
              <Link className="mb-5" to="/">
                <Logo className="bm-navbar__logo" fill="#fff" style={{ height: 50 }} />
              </Link>
              <h3 className="text-white mb-5">presents</h3>
              <h1 className="text-white" style={{ fontSize: '6.5rem' }}><span className="bm-Fandom__text--outline text-primary text-capitalize">{fandom}</span> DATING APP</h1>
              <p className="text-white mb-3 lead">{headerDescription}</p>
              <div className="mb-5" style={{ width: 250 }}>
                {/* <SubscribeButton text={headerButtonText} /> */}
                <Button
                  variant="primary"
                  onClick={() => {
                    this.setState({ quizModal: true });
                  }}
                  style={{ width: '100%', borderRadius: 30, fontWeight: 700, height: 150 }}
                >
                  {headerButtonText}
                </Button>
              </div>
              {!waitlistLoading && (
                <div data-aos="fade-in" data-aos-duration="200" className="d-flex justify-content-center align-items-center rounded flex-wrap container">
                  <div className="bm-Fandom_testimonialImages">
                    <div className="d-flex mr-3">
                      {this.images.slice(0,3).map((image: string, i) => <Image key={i} src={image} style={{ zIndex: this.images.length - i , height: 45, width: 45, borderRadius: '50%', objectFit: 'cover', marginLeft: i === 0 ? 0 : -8, border: '2px solid #fff' }} />)}
                    </div>
                  </div>
                  {waitlistTotal ? (
                    <p
                      className="text-white m-0 lead"
                    >
                      Join <span className="text-bold" style={{ fontWeight: 800 }}>{`${Math.floor(waitlistTotal / 50) * 50}+`}</span> {artistName} fans who signed up to date you!
                    </p>
                  ): (
                    <p
                      className="text-white m-0 lead"
                    >
                      Join hundreds of {artistName} fans who signed up to date you!
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          {productHuntBadge && (
              <div data-aos="fade-in" className="pt-3 pb-3 bm-Fandom_productHuntBadge" style={{ position: 'absolute', top: 25, right: 10 }}>
                {productHuntBadge}
              </div>
            )}
        </section>
        <section className="container-fluid d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '20vh', width: '100%', backgroundColor: '#000' }}>
          <div className="d-flex flex-column justify-content-center align-items-center p-1" style={{ width: '100%' }}>
            <h1 className="text-primary text-center" style={{ marginBottom: -15, fontSize: '6rem' }}>{headerQuote}</h1>
          </div>
        </section>
        <section className="d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: 'rgb(25 25 25)', height: '20vh', position: 'relative', width: '100%' }}>
          <h6 className="text-white bm-Home_pressText">SYNCS WITH</h6>
          <div style={{ width: '100%' }}>
            <div className="container d-flex justify-content-center align-items-center flex-wrap">
              <Image className="bm-Home_pressLogo mr-5" src={Spotify} fluid />
              <Image className="bm-Home_pressLogo ml-5" src={AppleMusic} fluid />
            </div>
          </div>
        </section>
        {/* <section className="mb-5" style={{ backgroundColor: 'rgb(25 25 25)' }}>
          <div className="container" style={{ height: '100%', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <div data-aos="fade-in" className={`container d-flex justify-content-between align-items-center flex-wrap`} style={{ display: 'block', paddingBottom: '3rem', width: '100%' }}>
                <div className="bm-Home__bannerImage bm-Home__bannerImage--large">
                  <Image src={radarImage} width="100%" fluid />
                </div>
                <div data-aos="fade-in" className="bm-Home__bannerDescription">
                  <h1 className="text-bold text-white mb-4">{radarTitle}</h1>
                  <p className="text-white">Dating is hard. Especially when <b>bad music taste</b> is a <Link to="/the-beat/673112183809490944/your-music-taste-will-make-or-break-your-dating" target="_blank"><b>deal breaker</b></Link>. With Beatmatch, we do the work for you. You play {artistName}; we find your match.</p>
                  <SubscribeButton variant="primary" text="GET STARTED" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {this.renderFeaturedSection({
          title: radarTitle,
          description: <p className="text-white">Dating is hard. Especially when <b>bad music taste</b> is a <Link to="/the-beat/673112183809490944/your-music-taste-will-make-or-break-your-dating" target="_blank"><b>deal breaker</b></Link>. With Beatmatch, we do the work for you. You play {artistName}; we find your match.</p>,
          image: radarImage,
          backgroundColor: 'rgb(25 25 25)',
          buttonText: radarButtonText,
        })}
        {this.renderFeaturedSection({
          title: firstSectionTitle,
          description: firstSectionDescription,
          image: firstSectionImage,
          reverse: true,
          className: 'pt-5 pb-5',
        })}
        {this.renderBannerSection({
          title: firstBannerTitle,
          description: firstBannerDescription,
          buttonText: firstBannerButtonText,
        })}
        {this.renderFeaturedSection({
          title: secondSectionTitle,
          description: secondSectionDescription,
          image: secondSectionImage,
          className: 'pt-5 pb-5',
        })}
        {this.renderBannerSection({
          title: secondBannerTitle,
          description: secondBannerDescription,
          buttonText: secondBannerButtonText,
        })}
        {this.renderFeaturedSection({
          title: thirdSectionTitle,
          description: thirdSectionDescription,
          image: thirdSectionImage,
          reverse: true,
          className: 'pt-5 pb-5',
        })}
        <Modal
          animation={true}
          centered={true}
          size="lg"
          show={quizModal}
          onShow={() => {
            this.setState({ quizModal: true });
          }}
          onHide={() => {
            this.setState({ quizModal: false, selectedItemIds: {}, activeStepIndex: 0 });
          }}
        >
          <Modal.Body>
            <Form onSubmit={this.handleJoin}>
              <div style={{ margin: '-1rem', padding: '1rem', maxHeight: 500, overflow: 'auto' }}>
                <Stepper
                  activeStepIndex={activeStepIndex}
                  steps={steps}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                this.setState({ quizModal: false, selectedItemIds: {}, activeStepIndex: 0 });
              }}
            >
              Close
            </Button>
            {activeStepIndex < steps.length - 1 && (
              <Button
                variant="primary"
                type="button"
                disabled={!Boolean(selectedItemIds[activeStepIndex])}
                onClick={() => {
                  this.setState(({ activeStepIndex: prevActiveStepIndex }) => ({
                    activeStepIndex: prevActiveStepIndex + 1,
                  }));
                }}
              >
                Next
              </Button>
            )}
            {activeStepIndex === steps.length - 1 && (
              <Button
                loading={loading}
                variant="primary"
                onClick={this.handleJoin}
                disabled={!Boolean(firstName && email)}
              >
                Join
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          animation={true}
          centered={true}
          size="lg"
          show={successModal}
          onShow={() => {
            this.setState({ successModal: true });
          }}
          onHide={() => {
            this.setState({ successModal: false });
          }}
        >
          <Modal.Body className="bm-SubscribeModal_body d-flex align-items-center" style={{ padding: 0 }}>
            <div className="bm-SubscribeModal_coverImage d-flex justify-content-end pt-3 pr-2" style={{ backgroundImage: `url(${successImage})` }}>
              <Button onClick={() => { this.setState({ successModal: false }); }} className="bm-SubscribeModal_coverImage__closeButton">
                <i className="fa fa-close" />
              </Button>
            </div>
            <div className="d-flex flex-column justify-content-between pl-4 pr-4 pt-2 pb-2" style={{ flex: 1 }}>
              <div>
                <h3><b>Happy April Fools{(member && member.firstName && `, ${member.firstName}`) || ''}! {placeInLine}</b></h3>
                <p className="mb-3">We were joking! Sort of. Beatmatch helps you find people with <b>similar music tastes.</b> You should try it. Check your email to join the party!</p>
                <div className="mb-3">
                  <h5 className="text-bold mb-2">Invite friends</h5>
                  <div className="bm-SubscribeModal__shareButtons">
                    <PostShareButtons url={referralLink} color={true} />
                  </div>
                  <p className="mb-1" style={{ padding: 0, margin: 0 }}>Or share your link</p>
                  <div className="mb-3">
                    <a
                      href={referralLink}
                      target="_blank"
                      className="text-primary"
                      style={{ fontSize: 13 }}
                    >
                      {referralLink}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => { dispatch(addNotification(notification)); },
});

export default connect(null, mapDispatchToProps)(Fandom);