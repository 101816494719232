/* External dependencies */
import React from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Cache, Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import Cognito from 'aws-sdk/clients/cognitoidentity';
import { setUserProperties, setUserId } from 'firebase/analytics';
import AOS from 'aos';

/* Internal dependencies */
import amplifyInit from './aws-exports';
import './App.scss';
import Music from './music/Music';
import EditTasteTest from './tasteTest/EditTasteTest';
import store, { ApplicationState } from './store';
import ViewTaste, { ViewTasteRedirect } from './tasteTest/ViewTaste';
import ViewResult from './tasteTest/ViewResult';
import TastemakersCampus from './ambassadors/TastemakersCampus';
import About from './about/About';
import { updateCurrentUser, CurrentUserState, updateCurrentUserLoading, getCurrentUser } from './store/ducks/currentUser';
import { getUser } from './api/users';
import { modelUser } from './store/helpers/users';
import ScrollToTop from './scrollToTop/ScrollToTop';
import Navbar from './navbar/Navbar';
import NotificationsContainer from './notifications/NotificationsContainer';
import Blog from './blog/Blog';
import Post from './blog/Post';
import AuthLandingPage from './auth/AuthLandingPage';
import Admin from './admin/Admin';
import ViewUser from './admin/ViewUser';
import Home from './home/Home';
import { analytics } from './firebase';
import ExitModal from './subscribe/ExitModal';
import TermsAndConditions from './legal/TermsAndConditions';
import PrivacyPolicy from './legal/PrivacyPolicy';
import CookiePolicy from './legal/CookiePolicy';
import Beyhive from './fandoms/Beyhive';
import BTSArmy from './fandoms/BTSArmy';
import Beliebers from './fandoms/Beliebers';

// Initialize AWS Amplify client
amplifyInit();
AOS.init({ offset: 125, delay: 50, duration: 400, once: true });

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = StateProps;

const cognitoIdentity = new Cognito({ region: 'us-west-2' });

export const NotFound = () => (
  <>
    <Helmet>
      <title>Beamatch | Not Found - 404</title>
    </Helmet>
    <Navbar />
    <div className="container d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h2>Uh oh. You're way off beat!</h2>
      <div>
        <p className="lead">The page you requested cannot be found. Try another page.</p>
      </div>
    </div>
  </>
);

export const getSignedInStatus = async () => {
  try {
    store.dispatch(updateCurrentUserLoading(true));
    let [authUser, { authenticated, identityId }] = await Promise.all([
      Auth.currentAuthenticatedUser(),
      Auth.currentUserCredentials(),
    ]);

    console.log('authUser', authUser);
    console.log('authenticated', authenticated);
    console.log('identityId', identityId);

    if (!identityId) {
      const token = await Cache.getItem(`fbToken_${process.env.NODE_ENV}`);
      const { IdentityId } = await cognitoIdentity.getId({
        AccountId: process.env.REACT_APP_ACCOUNT_ID || '',
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
        Logins: {
          'graph.facebook.com': token,
        }
      }).promise();
      identityId = IdentityId!;
    }

    if (authUser && authenticated && identityId) {
      const user = await getUser(`user:${identityId}`);
      const modeledUser = modelUser(user);
      store.dispatch(updateCurrentUser(modeledUser));
      setUserProperties(analytics, user);
      setUserId(analytics, user.id);
      return true;
    }
  } catch (e) {
    console.log('authentication error', e);
    console.log('authentication error message', e.message);
  } finally {
    store.dispatch(updateCurrentUserLoading(false));
  }
  return false;
};

class AppWrapper extends React.Component<Props> {
  shouldComponentUpdate({ currentUser }: Props) {
    // if (currentUser) {
    //   const omittedValues = ['images', 'birthday','artists','events','artistsOffset', 'eventsOffset', 'artistIds', 'eventIds', 'credentials', 'testUser', 'deviceTokens', 'answers', 'location', 'preferences', 'devices'];
    //   await Promise.all([
    //     crashlytics().setUserId(currentUser.id),
    //     crashlytics().setAttributes(omit(currentUser, omittedValues)),
    //     analytics().setUserId(currentUser.id),
    //     analytics().setUserProperties(omit(currentUser, omittedValues)),
    //   ]);
    //   amplifyInitWithCurrentUser(currentUser);
    // }
    return true;
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const AppContainer = connect(mapStateToProps)(AppWrapper) as any;

class App extends React.Component {
  async componentDidMount() {
    await getSignedInStatus();
  }

  updateCurrentUser = (user: CurrentUserState['user']) => {
    store.dispatch(updateCurrentUser(user));
  };

  render() {
    return (
      <Provider store={store}>
        <AppContainer>
          <>
          <Router>
            <ScrollToTop />
            <div id="bm" style={{ minHeight: '100vh', height: '100%' }}>
              <Switch>
                <Route
                  exact
                  key="home"
                  path="/"
                  // component={Music}
                  component={Home}
                  // component={() => <Redirect to="/music" />}
                />
                <Route
                  exact
                  key="spotify"
                  path="/spotify"
                  // component={Music}
                  component={(props: any) => <Home {...props} spotify={true} />}
                  // component={() => <Redirect to="/music" />}
                />
                <Route
                  exact
                  key="home"
                  path="/music"
                  component={Music}
                />
                <Route
                  exact
                  key="beyhive"
                  path="/beyhive"
                  component={Beyhive}
                />
                <Route
                  exact
                  key="bts-army"
                  path="/bts-army"
                  component={BTSArmy}
                />
                <Route
                  exact
                  key="beliebers"
                  path="/beliebers"
                  component={Beliebers}
                />
                <Route
                  path="/taste"
                  key="viewTasteRedirect"
                  exact={true}
                  component={ViewTasteRedirect}
                />
                <Route
                  key="viewTaste"
                  path="/taste/user:us-west-2::userId/preview"
                  exact={true}
                  component={({ match: { params: { userId }}}: any) => <Redirect to={`/taste/${userId}/preview`} />}
                />
                <Route
                  key="viewTaste"
                  path="/taste/:userId/preview"
                  exact={true}
                  component={ViewTaste}
                />
                <Route
                  key="viewTaste"
                  path="/taste/user:us-west-2::userId"
                  exact={true}
                  component={({ match: { params: { userId }}}: any) => <Redirect to={`/taste/${userId}`} />}
                />
                <Route
                  key="viewTaste"
                  path="/taste/:userId"
                  exact={true}
                  component={ViewTaste}
                />
                <Route
                  key="viewResult1"
                  path="/taste/user:us-west-2::userId/results/result::resultId"
                  exact={true}
                  component={({ match: { params: { userId, resultId }}}: any) => <Redirect to={`/taste/${userId}/results/${resultId}`} />}
                />
                <Route
                  key="viewResult2"
                  path="/taste/:userId/results/result::resultId"
                  exact={true}
                  component={({ match: { params: { userId, resultId }}}: any) => <Redirect to={`/taste/${userId}/results/${resultId}`} />}
                />
                <Route
                  key="viewResultTab"
                  path="/taste/:userId/results/:resultId/:tab"
                  exact={true}
                  component={ViewResult}
                />
                <Route
                  key="viewResult3"
                  path="/taste/:userId/results/:resultId"
                  exact={true}
                  component={(props: any) => <ViewResult {...props} />}
                />
                <Route
                  key="ambassadors"
                  path="/ambassadors/campus-tastemakers"
                  exact={true}
                  component={TastemakersCampus}
                />
                <Route
                  key="about"
                  path="/about"
                  exact={true}
                  component={About}
                />
                <Route
                  key="blogPost"
                  path="/the-beat/:postId/:slug"
                  exact={true}
                  component={Post}
                />
                <Route
                  key="blogPost"
                  path="/the-beat/:postId"
                  exact={true}
                  component={Post}
                />
                <Route
                  key="blog"
                  path="/the-beat"
                  exact={true}
                  component={Blog}
                />
                <Route
                  key="editTaste1"
                  path="/taste/user:us-west-2::userId/edit"
                  exact={true}
                  component={({ match: { params: { userId }}}: any) => <Redirect to={`/taste/${userId}/edit`} />}
                />
                <Route
                  key="editTaste2"
                  path="/taste/:userId/edit"
                  exact={true}
                  component={EditTasteTest}
                />
                <Route
                  key="sync"
                  path="/sync"
                  component={() => null}
                />
                <Route
                  key="terms-and-conditions"
                  path="/terms-and-conditions"
                  component={TermsAndConditions}
                />
                <Route
                  key="privacy-policy"
                  path="/privacy-policy"
                  component={PrivacyPolicy}
                />
                <Route
                  key="cookie-policy"
                  path="/cookie-policy"
                  component={CookiePolicy}
                />
                <Route
                  key="auth/facebook"
                  path="/auth/facebook"
                  component={AuthLandingPage}
                />
                <Route
                  key="admin"
                  path="/admin"
                  exact={true}
                  component={Admin}
                />
                <Route
                  key="admin"
                  path="/admin/users"
                  exact={true}
                  component={Admin}
                />
                <Route
                  key="admin"
                  path="/admin/users/:userId"
                  component={ViewUser}
                />
                <Route
                  key="admin"
                  path="/admin/users/:userId/:tab"
                  exact={true}
                  component={ViewUser}
                />
                <Route
                  key="notFound"
                  component={NotFound}
                  path="/not-found"
                />
                <Route
                  key="404"
                  component={NotFound}
                />
              </Switch>
            </div>
          </Router>
          <NotificationsContainer />
          </>
        </AppContainer>
        <ExitModal />
      </Provider>
    );
  }
}

export default App;