/* External dependencies */
import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

/* Internal dependencies */
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import { updateCurrentUser, getCurrentUser, CurrentUserState, resetCurrentUser } from '../store/ducks/currentUser';
import { ApplicationState } from '../store';
import { User } from '../types/User';
import './Navbar.scss';
import Button from '../button/Button';
import SubscribeButton from 'src/subscribe/SubscribeButton';

type InjectedProps = {
  history: any;
  location: any;
};

type OwnProps = {
  showLogo?: boolean;
  alwaysFillNavbar?: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  updateCurrentUser(user: User): void;
  resetCurrentUser(): void;
};

type Props = InjectedProps & OwnProps & StateProps & DispatchProps;

type State = {
  fillNavbar: boolean;
  drawerOpen: boolean;
};

class Navbar extends React.Component<Props, State> {
  state = {
    fillNavbar: (window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop) > 45 ? true : false,
    drawerOpen: false,
  };

  componentDidMount() {
    const { alwaysFillNavbar = true } = this.props;
    if (!alwaysFillNavbar) {
      if (typeof (window) !== "undefined") {
        window.document.addEventListener('scroll', this.handleScroll);
      }
    }
    if (typeof (window) !== "undefined") {
      const toggle = window.document.getElementById('toggle');
      const menu = window.document.querySelector('.offcanvas-collapse');
      if (toggle) {
        toggle.addEventListener('click', (e) => {
          if (menu) {
            menu.classList.toggle('open');
            this.setState(({ drawerOpen }) => ({ drawerOpen: !drawerOpen }));
          }
        });
      }
    }
  }

  componentWillUnmount() {
    if (typeof (window) !== "undefined") {
      window.document.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if (typeof (window) !== "undefined") {
      const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

      if (scrollTop > 45) {
        this.setState({ fillNavbar: true });
      } else {
        this.setState({ fillNavbar: false });
      }
    }
  };

  handleSignOut = async () => {
    const { history, resetCurrentUser } = this.props;
    try {
      await Auth.signOut({ global: true });
      resetCurrentUser();
      history.push('/');
    } catch (e) { }
  };

  render() {
    const { showLogo = true, alwaysFillNavbar = true, currentUser, location } = this.props;
    const { fillNavbar, drawerOpen } = this.state;

    return (
      // <nav className={`bm-navbar navbar navbar-expand-lg navbar-light sticky-top${fillNavbar || alwaysFillNavbar ? ' bm-navbar--fill' : ''}`}>
      //   <div className="bm-navbar__body container">
      //     <Link className="navbar-brand" to="/">
      //       <Logo className="bm-navbar__logo" fill={fillNavbar || alwaysFillNavbar ? '#96f' : '#fff'} />
      //     </Link>
      //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      //       <span className="navbar-toggler-icon"></span>
      //     </button>
      //     <div className="d-none d-sm-none d-md-none d-lg-block">
      //       <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      //         <ul className={`navbar-nav bm-navbar__rightNav${fillNavbar || alwaysFillNavbar ? ' bm-navbar__rightNav--fill' : ''}`}>
      //           <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
      //           <li className="nav-item"><Link className="nav-link" to="/taste">Taste Quiz</Link></li>
      //           <li className="nav-item"><Link className="nav-link" to="/sync">Sync Up</Link></li>
      //           {currentUser && (
      //             <img className="img img-fluid img-thumbnail rounded-circle" src={currentUser.images && currentUser.images[0].url} width={40} />
      //           )}
      //         </ul>
      //       </div>
      //     </div>
      //   </div>
      // </nav>
      <nav className={`bm-navbar navbar navbar-expand-lg navbar-light sticky-top ${fillNavbar || alwaysFillNavbar || drawerOpen ? ' bm-navbar--fill' : ''}`}>
        <div className="bm-navbar__body container">
          <NavLink className="navbar-brand d-flex align-items-center flex-wrap" to={location && location.pathname.startsWith('/taste') ? '/taste' : '/'}>
            {showLogo && <Logo className="bm-navbar__logo mr-3" fill={fillNavbar || alwaysFillNavbar || drawerOpen ? '#96f' : '#fff'} />}
            {location && location.pathname.startsWith('/taste') && (
              <div className="bm-navbar__tasteTest">
                <div className="mr-3" style={{ borderLeft: '3px solid #fff', height: 22.5, borderColor: fillNavbar ? '#96f' : '#fff' }} />
                <h2 style={{ color: fillNavbar ? '#96f' : '#fff', marginBottom: 0, paddingBottom: 0, fontSize: '1.6rem', lineHeight: '1.5rem', marginTop: '0.25rem' }}>Taste Test</h2>
              </div>
            )}
          </NavLink>
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <button id="toggle" className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse offcanvas-collapse justify-content-end" id="navbarNavAltMarkup">
            <ul className={`navbar-nav bm-navbar__rightNav${fillNavbar || alwaysFillNavbar || drawerOpen ? ' bm-navbar__rightNav--fill' : ''}`}>
              <li className="nav-item"><NavLink exact={true} activeClassName="nav-link bm-navbar__link bm-navbar__link--active" className="nav-link bm-navbar__link" to="/">The App</NavLink></li>
              <li className="nav-item"><NavLink activeClassName="nav-link bm-navbar__link bm-navbar__link--active" className="nav-link bm-navbar__link" to="/taste">Taste Test</NavLink></li>
              {/* <li className="nav-item"><NavLink exact={true} activeClassName="nav-link bm-navbar__link bm-navbar__link--active" className="nav-link bm-navbar__link" to="/sync">Sync Up</NavLink></li> */}
              <li className="nav-item"><NavLink exact={true} activeClassName="nav-link bm-navbar__link bm-navbar__link--active" className="nav-link bm-navbar__link" to="/ambassadors/campus-tastemakers">Ambassadors</NavLink></li>
              <li className="nav-item"><NavLink exact={true} isActive={(_, location) => Boolean(location && location?.pathname.startsWith('/the-beat'))} activeClassName="nav-link bm-navbar__link bm-navbar__link--active" className="nav-link bm-navbar__link" to="/the-beat">The Beat</NavLink></li>
              {currentUser ? (
                <li className="nav-item d-flex align-items-center">
                  <Button
                    className="btn nav-link bm-navbar__link bm-navbar__link--active"
                    style={{ border: 'none', padding: 0, margin: 0 }}
                    dropdownItems={[
                      <Dropdown.Item
                        key="update"
                        onClick={this.handleSignOut}
                      >
                        Sign Out
                      </Dropdown.Item>,
                    ]}
                  >
                    {currentUser.images && currentUser.images[0] && <img alt={currentUser.name} className="img img-fluid img-thumbnail rounded-circle mr-2" src={currentUser.images && currentUser.images[0] && currentUser.images[0].url} width={40} />}
                    <h6 className="m-0 p-0">{currentUser.name}</h6>
                  </Button>
                </li>
              ) : (
                <div>
                  <SubscribeButton text="JOIN THE PARTY" variant={fillNavbar ? 'primary' : 'secondary'} color={fillNavbar ? '#fff' : '#9966ff'} />
                </div>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state : ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: User) => { dispatch(updateCurrentUser(user)); },
  resetCurrentUser: () => { dispatch(resetCurrentUser()); },
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(Navbar));