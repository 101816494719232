
// TODO: Type out API response to ensure modeling works correctly
export const modelUser = (user: any) => ({
  ...user,
  // answers: (user.answers || []).reduce((acc: any, curr: any) => ({
  //   ...acc,
  //   [curr.id]: curr,
  // }), {}),
  artistIds: new Set(user.artistIds || []),
});
