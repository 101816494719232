/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';

export const listPrompts = async (limit: number = 30) =>
  await API.get(ApiName.bmApp, `/prompts`, {
    queryStringParameters: { limit },
  });

export const addPrompt = async (prompt: any) =>
  await API.post(ApiName.bmApp, `/prompts`, { body: prompt });