/* External dependencies */
import React from 'react';
import Helmet from 'react-helmet';

/* Internal dependencies */
import Navbar from 'src/navbar/Navbar';

const PrivacyPolicy = () => {
  const pageTitle = 'Beatmatch | Privacy Policy';
  const pageDescription = 'The Beatmatch privacy policy';
  const pageUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/privacy-policy`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:site_name" content="Beatmatch" />
        <meta property="twitter:description" content={pageDescription} />
        <meta property="twitter:url" content={pageUrl} />
        <meta property="twitter:image:alt" content={pageTitle} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:card" content="summary" />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Navbar />
      <div className="container pt-5 pb-3">
        <h2 className="text-left mb-5">Privacy Policy</h2>
        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to Beatmatch,
          and Beatmatch and governs data collection and usage. For the purposes of this Privacy Policy,
          unless otherwise noted, all references to Beatmatch include https://www.beatmatchsocial.com or https://www.beatmatch.app. The
          Beatmatch application is a dating app that matches people with similar music tastes. By using the Beatmatch application, you consent to the data practices described in this
          statement.
        </p>
        <p>
          Collection of your Personal Information
          In order to better provide you with products and services offered, Beatmatch may collect
          personally identifiable information, such as your:
        </p>
        <p>
          Beatmatch may also collect anonymous demographic information, which is not unique to you, such
          as your:
        </p>
        <p>
          We do not collect any personal information about you unless you voluntarily provide it to us.
          However, you may be required to provide certain personal information to us when you elect to use
          certain products or services. These may include: (a) registering for an account; (b) entering a
          sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers
          from selected third parties; (d) sending us an email message; (e) submitting your credit card or
          other payment information when ordering and purchasing products and services. To wit, we will
          use your information for, but not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional personal or non-personal
          information in the future.
        </p>
        <p>
          Use of your Personal Information
          Beatmatch collects and uses your personal information to operate and deliver the services you
          have requested.
        </p>
        <p>
          Beatmatch may also use your personally identifiable information to inform you of other products or
          services available from Beatmatch and its affiliates.
        </p>
        <p>
          Sharing Information with Third Parties
          Beatmatch does not sell, rent or lease its customer lists to third parties.
        </p>
        <p>
          Beatmatch may share data with trusted partners to help perform statistical analysis, send you email
          <ul>
            <li>First and Last Name</li>
            <li>E-mail Address</li>
            <li>Phone Number</li>
            <li>Age</li>
            <li>Gender</li>
          </ul>
          or postal mail, provide customer support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide these services to Beatmatch,
          and they are required to maintain the confidentiality of your information.
        </p>
        <p>
          Beatmatch may disclose your personal information, without notice, if required to do so by law or in
          the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
          with legal process served on Beatmatch or the site; (b) protect and defend the rights or property of
          Beatmatch; and/or (c) act under exigent circumstances to protect the personal safety of users of
          Beatmatch, or the public.
        </p>
        <p>
          Right to Deletion
          Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
        </p>
        <p>
          Please note that we may not be able to comply with requests to delete your personal information if
          it is necessary to:
        </p>
        <p>
          Children Under Thirteen
          Beatmatch does not knowingly collect personally identifiable information from children under the
          age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
          permission to use this application.
        </p>
        <p>
          Disconnecting your Beatmatch Account from Third Party Websites
          <ul>
          <li>Delete your personal information from our records; and</li>
          <li>Direct any service providers to delete your personal information from their records.</li>
          <li>Complete the transaction for which the personal information was collected, fulfill the
          terms of a written warranty or product recall conducted in accordance with federal
          law, provide a good or service requested by you, or reasonably anticipated within the
          context of our ongoing business relationship with you, or otherwise perform a contract
          between you and us;</li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
          activity; or prosecute those responsible for that activity;</li>
          <li>Debug to identify and repair errors that impair existing intended functionality;</li>
          <li>Exercise free speech, ensure the right of another consumer to exercise his or her right
          of free speech, or exercise another right provided for by law;</li>
          <li>Comply with the California Electronic Communications Privacy Act;</li>
          <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the
          public interest that adheres to all other applicable ethics and privacy laws, when our
          deletion of the information is likely to render impossible or seriously impair the
          achievement of such research, provided we have obtained your informed consent;</li>
          <li>Enable solely internal uses that are reasonably aligned with your expectations based on
          your relationship with us;</li>
          <li>Comply with an existing legal obligation; or</li>
          <li>Otherwise use your personal information, internally, in a lawful manner that is
          compatible with the context in which you provided the information.

          You will be able to connect your Beatmatch account to third party accounts. BY CONNECTING
          YOUR BEATMATCH ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU
          ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE
          CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN
          ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES).
          IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY
          IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE
          THIS FEATURE. You may disconnect your account from a third party account at any time. Users
          can disconnect access to their accounts from third-party websites by visiting their "Settings" page.
          Users may also contact us via email or telephone.</li>
        </ul>
        </p>
        <p>
          E-mail Communications
          From time to time, Beatmatch may contact you via email for the purpose of providing
          announcements, promotional offers, alerts, confirmations, surveys, and/or other general
          communication. In order to improve our Services, we may receive a notification when you open an
          email from Beatmatch or click on a link therein.
        </p>
        <p>
          If you would like to stop receiving marketing or promotional communications via email from
          Beatmatch, you may opt out of such communications by clicking the "Unsubscribe" link in any
          email received from Beatmatch..
        </p>
        <p>
          External Data Storage Sites
          We may store your data on servers provided by third party hosting vendors with whom we have
          contracted.
        </p>
        <p>
          Changes to this Statement
          Beatmatch reserves the right to change this Privacy Policy from time to time. We will notify you
          about significant changes in the way we treat personal information by sending a notice to the
          primary email address specified in your account, by placing a prominent notice on our application,
          and/or by updating any privacy information. Your continued use of the application and/or Services
          available after such modifications will constitute your: (a) acknowledgment of the modified Privacy
          Policy; and (b) agreement to abide and be bound by that Policy.
        </p>
        <p>
          Contact Information
          Beatmatch welcomes your questions or comments regarding this Statement of Privacy. If you
          believe that Beatmatch has not adhered to this Statement, please contact Beatmatch at:
        </p>
        <p>
          Beatmatch
          1131 PO Box
          Torrance, California 90505
        </p>
        <p>
          Email Address:
          contact@beatmatchsocial.com
        </p>
        <p>
          Effective as of November 18, 2021 
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;