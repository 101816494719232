/* External dependencies */
import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import confetti from 'canvas-confetti';
import { logEvent } from '@firebase/analytics';

/* Internal dependencies */
import DontMissTheParty from '../assets/images/dont-miss-the-party.png';
import JoinCover from '../assets/images/join-cover.png';
import YoureInTheLineCover from '../assets/images/youre-on-the-list-cover.png';
import { addNotification } from '../store/ducks/notifications';
import { Notification } from '../types/Notification';
import Button from '../button/Button';
import PostShareButtons from '../blog/PostShareButtons';
import './SubscribeModal.scss';
import { addMemberForLaunchWaitlist, updateMemberForLaunchWaitlist } from 'src/api/waitlists';
import { Member } from 'src/types/Member';
import { analytics } from 'src/firebase';

type OwnProps = {
  initialEmail?: string;
  member?: Member;
  variant?: 'primary' | 'success';
  show?: boolean;
  onShow?: () => void;
  onHide?: () => void;
  isExitModal?: boolean;
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & DispatchProps;

type State = {
  email?: string;
  member?: Member;
  firstName?: string;
  // lastName?: string;
  loading: boolean;
  currentStepIndex: number;
};

class SubscribeModal extends React.Component<Props, State> {
  confetti: confetti.CreateTypes | undefined;

  constructor(props: Props) {
    super(props);
    
    const { initialEmail, member } = this.props;
    this.state = {
      email: initialEmail,
      member,
      loading: false,
      currentStepIndex: member && member.firstName ? 1 : 0,
      firstName: member && member.firstName,
      // lastName: member && member.lastName,
    } as State;
  }

  componentDidMount() {
    this.confetti = confetti.create(null as any, { resize: true });
    if (this.state.firstName) {
      this.popConfetti();
    }
  }

  popConfetti = () => {
    if (this.confetti) {
      this.confetti({
        particleCount: 100,
        spread: 160,
        zIndex: 100000,
        colors: ['#9966ff', '#9966ff', '#fff', '#000'],
      });
    }
  };

  hideModal = () => {
    const { onHide } = this.props;

    if (onHide) {
      onHide();
    }
    this.setState({ currentStepIndex: 0 });
    logEvent(analytics, 'waitlist_closeModal', this.state);
  };

  join = async () => {
    const { initialEmail, member, addNotification } = this.props;
    const { email, firstName } = this.state;

    if ((!email && !initialEmail) || !firstName) {
      addNotification({
        title: 'You skipped a beat!',
        message: 'You must enter your first name and email address to join the party.',
        variant: 'danger',
      });
      return;
    }

    try {
      this.setState({ loading: true });
      if (initialEmail) {
        const updatedMember = await updateMemberForLaunchWaitlist({ email: initialEmail || email, firstName, id: member && member.id || `member:${initialEmail || email}` });
        this.setState({ member: updatedMember });
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const referralId = urlParams.get('ref_id') || undefined;
        const updatedMember = await addMemberForLaunchWaitlist({ email: email!, firstName, referralId });
        this.setState({ member: updatedMember });
      }
      addNotification({
        title: `${firstName}, you\'re locked in!`,
        message: 'You\'re on the waitlist. Stay tuned to find out when a spot opens up!',
        variant: 'primary',
      });
      this.popConfetti();
      this.setState({ currentStepIndex: 1 });
      // this.hideModal();
    } catch (e) {
      console.log('error with user subscription', e);
      addNotification({
        title: 'Already On The Waitlist',
        message: 'You have already been added to the waitlist. Check your email for updates!',
        variant: 'danger',
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { initialEmail, show, isExitModal = false } = this.props;
    const { loading, currentStepIndex, member } = this.state;
    let position: number = 0;

    if (member && member.position !== undefined && member.position !== null) {
      position = member.position;
    };

    const referralLink = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}?ref_id=${member && member!.referralId}`;
    let placeInLine = <span><span className="text-primary">{position > 0 ? position - 1 : 0}</span> {position === 2 ? 'person is' : 'people are'} ahead of you.</span>
    if (position === 1) {
      placeInLine = <span>You're <span className="text-primary">1st</span> in line!</span>;
    }
    let description = <span>Hate long lines? We do too. Invite your friends to move up the waitlist. Each friend that joins moves you up <span className="text-primary text-bold">10 spots</span>!</span>;
    if (position === 1) {
      description = <span>Want to stay in front of the line? Invite your friends to remain in the top spot on the waitlist. Each friend that joins helps you stay in the top spot!</span>;
    }

    return (
      <Modal animation={true} centered={true} size="lg" show={show} onHide={this.hideModal} backdrop={initialEmail && currentStepIndex === 0 ? 'static' : true}>
        <Modal.Body className="bm-SubscribeModal_body d-flex align-items-center" style={{ padding: 0 }}>
          <div className="bm-SubscribeModal_coverImage d-flex justify-content-end pt-3 pr-2" style={{ backgroundImage: `url(${currentStepIndex === 0 ? isExitModal ? DontMissTheParty  : JoinCover : YoureInTheLineCover})` }}>
            <Button onClick={this.hideModal} className="bm-SubscribeModal_coverImage__closeButton">
              <i className="fa fa-close" />
            </Button>
          </div>
          {currentStepIndex === 0 && (
            <Form className="d-flex flex-column justify-content-between p-4" style={{ flex: 1 }}>
              <div>
                <h3><b>{initialEmail ? 'Almost done!' : isExitModal ? 'Get notified when we launch!' : 'Join the party!'}</b></h3>
                <p className="mb-2">We'll contact you when the pre-game starts!</p>
                <div className="mb-3">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name"
                    autoFocus={true}
                    onInput={(e: any) => { this.setState({ firstName: e.target.value }); }}
                  />
                </div>
                {/* <div className="mb-3" style={{ width: '100%' }}>
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    onInput={(e: any) => { this.setState({ lastName: e.target.value }); }}
                  />
                </div> */}
                <div className="mb-3">
                  <Form.Label>Email</Form.Label>
                  {initialEmail ? <div><span>{initialEmail}</span></div> : (
                    <Form.Control
                      type="email"
                      placeholder="Enter your email address"
                      onInput={(e: any) => { this.setState({ email: e.target.value }); }}
                    />
                  )}
                </div>
              </div>
              <Button loading={loading} onClick={this.join} variant="primary" style={{ width: '100%', borderRadius: 30, fontWeight: 700, height: 45 }}>{isExitModal ? 'GET NOTIFIED' : 'COUNT ME IN'}</Button>
            </Form>
          )}
          {currentStepIndex === 1 && (
            <div className="d-flex flex-column justify-content-between p-4" style={{ flex: 1 }}>
              <div>
                <h3><b>Thank you{(member && member.firstName && `, ${member.firstName}`) || ''}! {placeInLine}</b></h3>
                <p className="mb-3">{description}</p>
                <div className="mb-3">
                  <h5 className="text-bold mb-2">Invite friends</h5>
                  <div className="bm-SubscribeModal__shareButtons">
                    <PostShareButtons url={referralLink} color={true} />
                  </div>
                  <p className="mb-1" style={{ padding: 0, margin: 0 }}>Or share your referral link</p>
                  <div className="mb-3">
                    <a
                      href={referralLink}
                      target="_blank"
                      className="text-primary"
                      style={{ fontSize: 13 }}
                    >
                      {referralLink}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => { dispatch(addNotification(notification)); },
});

export default connect(null, mapDispatchToProps)(SubscribeModal);
