/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cacheify, flushCacheify } from '../helpers/cacheify';

export const listAnswersForUser = cacheify(async (userId: string, fromTasteTest?: boolean): Promise<any[]> =>
  await API.get(ApiName.bmApp, `/users/${userId}/answers`, {
    queryStringParameters: {
      limit: 25, // TODO: using a limit of 25 to ensure we paginate far enough out to get all answers....should fix this eventually
      fromTasteTest,
    },
  }), 60000)();

export const addAnswerForUser = async (userId: string, { promptId, items, fromTasteTest }: any) => {
  const response = await API.post(ApiName.bmApp, `/users/${userId}/answers`, {
    body: {
      promptId,
      items,
      fromTasteTest,
    },
  });
  flushCacheify();
  return response;
};

export const updateAnswerForUser = async (userId: string, answer: any) => {
  const response = await API.put(ApiName.bmApp, `/users/${userId}/answers/${answer.id}`, {
    body: answer,
  });

  flushCacheify();
  return response;
}

export const removeAnswerForUser = async (userId: string, answerId: string) => {
  const response = await API.del(ApiName.bmApp, `/users/${userId}/answers/${answerId}`, {});

  flushCacheify();
  return response;
}
