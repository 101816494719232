/* External dependencies */
import React from 'react';

/* Internal dependencies */
import Fandom from './Fandom';
import MusicLoverRadar from '../assets/images/music-lover-radar-beyonce.png';
import DiscoverScreenshot from '../assets/images/discover-screenshot-beyonce.png';
import MusicScreenshot from '../assets/images/music-screenshot-beyonce.png';
import EventScreenshot from '../assets/images/event-screenshot-beyonce.png';
import WaitlistMember1 from '../assets/images/waitlist-member-1.jpeg';
import WaitlistMember2 from '../assets/images/waitlist-member-2.jpeg';
import WaitlistMember7 from '../assets/images/waitlist-member-7.jpeg';
import WaitlistMember8 from '../assets/images/waitlist-member-8.jpeg';
import WaitlistMember9 from '../assets/images/waitlist-member-9.jpeg';
import SuccessImage from '../assets/images/happy-april-fools-beyonce.png';

const Beyhive: React.FC = () => {
  return (
    <Fandom
      fandom="THE BEYHIVE"
      radarImage={MusicLoverRadar}
      radarTitle="Play Beyoncé. Find love."
      radarButtonText="GET IN FORMATION"
      title="THE BEYHIVE DATING APP"
      headerBackgroundClass="bm-Fandom__header_image--beyonce"
      headerDescription={(
        <>
          <p className="mb-4">Meet fans who love Beyoncé as much as you do</p>
          <p className="text-primary text-underline"><i>Becky with the good hair is not welcome</i></p>
        </>
      )}
      headerQuote={'"ALL THE SINGLE LADIES"'}
      headerButtonText="JOIN THE HIVE"
      artistName="Beyoncé"
      firstSectionTitle="Match with the Hive"
      firstSectionDescription="You are paired 100% based on music compatibility! No fake Queen Bey fans allowed."
      firstSectionImage={DiscoverScreenshot}
      secondSectionTitle="Discover new music"
      secondSectionDescription="Need some new tunes? Share your favorites songs with other mega fans!"
      secondSectionImage={MusicScreenshot}
      thirdSectionTitle="Never miss a show"
      thirdSectionDescription="Browse upcoming Beyoncé concerts, find out who wants to go, and invite someone for a date!"
      thirdSectionImage={EventScreenshot}
      firstBannerTitle="Put your love on top"
      firstBannerDescription="No more singing Beyoncé in your room by yourself. Now you can vibe together with fans in your area."
      firstBannerButtonText="FIND LOVE"
      secondBannerTitle="You're irreplaceable"
      secondBannerDescription="Don't settle for average music taste. Get someone who matches your wavelength!"
      secondBannerButtonText="LEVEL UP"
      playlistId="37i9dQZF1DX2oU49YwtXI2"
      slug="beyhive"
      successImage={SuccessImage}
      productHuntBadge={(
        <a
          href="https://www.producthunt.com/posts/the-beyhive-dating-app?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-the&#0045;beyhive&#0045;dating&#0045;app" target="_blank"
        >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338800&theme=light" alt="The&#0032;Beyhive&#0032;Dating&#0032;App - Meet&#0032;fans&#0032;who&#0032;love&#0032;Beyonc&#0233;&#0032;as&#0032;much&#0032;as&#0032;you&#0032;do&#0033; | Product Hunt"
              style={{ width: 225, height: 40 }}
              width={225}
              height={40}
            />
        </a>
      )}
      waitlistImages={[
        WaitlistMember1,
        WaitlistMember2,
        WaitlistMember7,
        WaitlistMember8,
        WaitlistMember9,
      ]}
    />
  );
};

export default Beyhive;