/* External dependencies */
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { differenceBy, cloneDeep } from 'lodash';
import { Redirect, match } from 'react-router-dom';
import { Modal, Form, Dropdown, Tabs, Tab } from 'react-bootstrap';

/* Internal dependencies */
import { updateCurrentUser, getCurrentUser, CurrentUserState } from '../store/ducks/currentUser';
import { User } from '../types/User';
import { ApplicationState } from '../store';
import { listAnswersForUser, addAnswerForUser, updateAnswerForUser } from '../api/answers';
import MediaObject from '../mediaObject/MediaObject';
import './EditTasteTest.scss';
import Button from '../button/Button';
import Spinner from '../spinner/Spinner';
import Stepper from '../stepper/Stepper';
import { listPrompts } from '../api/prompts';
import CheckCircle from '../checkCircle/CheckCircle';
import { searchTracks } from '../api/tracks';
import { PaginationOptions } from '../types/Pagination';
import { flushCacheify } from '../helpers/cacheify';
import { searchArtists } from '../api/artists';
import Item from '../item/Item';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import ChartsTable from './ChartsTable';
import { ReactComponent as Microphone } from '../assets/images/microphone.svg';
import { ReactComponent as MusicalNote } from '../assets/images/musical-note.svg';
import AuthRoutes from '../AuthRoutes';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';

enum ModalId {
  add = 'add',
  update = 'update',
  replace = 'replace',
}

type InjectedProps = {
  match: match<{ userId: string; resultId: string }>;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  updateCurrentUser(user: User): void;
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = InjectedProps & StateProps & DispatchProps;

type State = {
  prompts: any[];
  items: any[];
  loading: boolean;
  modalId: ModalId | undefined;
  activeStepIndex: number;
  selectedPromptId: string | undefined;
  selectedItemId: string | undefined;
  replacementAnswerId: string | undefined;
  answers: any[];
};

const MAX_NUMBER_OF_ANSWERS = 10;

class EditTasteTest extends React.Component<Props, State> {
  state = {
    prompts: [], items: [],
    loading: true, showModal: false,
    modalId: undefined,
    activeStepIndex: 0, selectedPromptId: undefined,
    selectedItemId: undefined,
    replacementAnswerId: undefined,
    answers: [],
  };

  async componentDidMount() {
    await this.load();
  }

  componentDidUpdate({ currentUser, match: { params } }: any) {
    const { userId } = params;

    if ((currentUser &&currentUser.id) !== (this.props.currentUser && this.props.currentUser.id) || userId !== this.props.match.params.userId) {
      this.load();
    }
  }

  load = async () => {
    const { currentUser, updateCurrentUser } = this.props;
    try {
      if (currentUser) {
        const [{ answers = [] }, { prompts = [] }]: any = await Promise.all([
          listAnswersForUser(currentUser.id, true),
          listPrompts(), // TODO: move this call to when add answer modal opens
        ]);
        this.setState({ prompts, answers });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  showModal = (modalId: ModalId) => { this.setState({ modalId }); };

  hideModal = () => {
    this.setState({ modalId: undefined, activeStepIndex: 0, items: [], selectedPromptId: undefined, selectedItemId: undefined, replacementAnswerId: undefined });
  };

  prevStep = () => { this.setState(({ activeStepIndex }) => ({ activeStepIndex: activeStepIndex - 1 })); };

  nextStep = () => { this.setState(({ activeStepIndex }) => ({ activeStepIndex: activeStepIndex + 1 })); };

  saveAnswer = async () => {
    const { currentUser, updateCurrentUser, addNotification } = this.props;
    const { items, selectedPromptId, selectedItemId, replacementAnswerId, prompts } = this.state;
    const item = items.find((item: any) => item.id === selectedItemId);

    if (currentUser) {
      const newAnswers = cloneDeep(currentUser.answers);
      let answer: any;
      const selectedPrompt = prompts.find(({ id }) => id === selectedPromptId);

      if (replacementAnswerId) {
        answer = { id: replacementAnswerId, promptId: selectedPromptId, items: [item], fromTasteTest: true };
        updateAnswerForUser(currentUser.id, answer);
      } else {
        answer = { promptId: selectedPromptId, items: [item], fromTasteTest: true }
        addAnswerForUser(currentUser.id, answer);
      }

      const indexOfOldAnswer = newAnswers.findIndex((oldAnswer: any) => Boolean(oldAnswer.id && answer.id && oldAnswer.id === answer.id));
      newAnswers[indexOfOldAnswer !== -1 ? indexOfOldAnswer : newAnswers.length] = { ...answer, prompt: selectedPrompt, type: 'answer' };
      updateCurrentUser({ ...currentUser, answers: newAnswers });
      flushCacheify();
      addNotification({ title: 'Nice Pick!', message: 'The answer has been added to your Taste Test.'});
      this.hideModal();
    }
  };

  handleQueryUpdate = async (q: string = '', options: PaginationOptions) => {
    const { prompts, selectedPromptId } = this.state;
    const selectedPrompt: any = prompts.find((prompt: any) => prompt.id === selectedPromptId);
    let api = searchTracks;
    if (selectedPrompt && selectedPrompt.promptType === 'artist') {
      api = searchArtists;
    }
    const { items } = await api(q, options);
    this.setState({ items });
  };

  renderAddModal = (steps: any[]) => {
    const { modalId, activeStepIndex, selectedPromptId, selectedItemId } = this.state;
    return (
      <Modal size="lg" show={modalId === ModalId.add} onHide={this.hideModal}>
        <Modal.Header translate={false}closeButton>
          <Modal.Title>Add an Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.saveAnswer}>
            <div style={{ margin: '-1rem', padding: '1rem', maxHeight: 500, overflow: 'auto' }}>
              <Stepper
                activeStepIndex={activeStepIndex}
                steps={steps}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={this.hideModal}>
            Close
          </Button>
          {activeStepIndex > 0 && (
            <Button variant="secondary" type="button" onClick={this.prevStep} disabled={!selectedPromptId}>
              Previous
            </Button>
          )}
          {activeStepIndex < steps.length - 1 && (
            <Button variant="primary" type="button" onClick={this.nextStep} disabled={!selectedPromptId}>
              Next
            </Button>
          )}
          {activeStepIndex === steps.length - 1 && (
            <Button variant="primary" onClick={this.saveAnswer} disabled={!selectedPromptId || !selectedItemId}>
              Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  renderUpdateModal = (steps: any[]) => {
    const { modalId, activeStepIndex, selectedPromptId, selectedItemId } = this.state;
    return (
      <Modal size="lg" show={modalId === ModalId.update} onHide={this.hideModal}>
        <Modal.Header translate={false} closeButton>
          <Modal.Title>Update Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.saveAnswer}>
            <div style={{ margin: '-1rem', padding: '1rem', maxHeight: 500, overflow: 'auto' }}>
              <Stepper
                activeStepIndex={activeStepIndex}
                steps={steps}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={this.hideModal}>
            Close
          </Button>
          {activeStepIndex < steps.length - 1 && (
            <Button variant="primary" type="button" onClick={this.nextStep} disabled={!selectedPromptId}>
              Next
            </Button>
          )}
          {activeStepIndex === steps.length - 1 && (
            <Button variant="primary" onClick={this.saveAnswer} disabled={!selectedPromptId || !selectedItemId}>
              Update Answer
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  renderReplaceModal = (steps: any[]) => {
    const { modalId, activeStepIndex, selectedPromptId, selectedItemId } = this.state;
    return (
      <Modal size="lg" show={modalId === ModalId.replace} onHide={this.hideModal}>
        <Modal.Header translate={false} closeButton>
          <Modal.Title>Replace Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.saveAnswer}>
            <div style={{ margin: '-1rem', padding: '1rem', maxHeight: 500, overflow: 'auto' }}>
              <Stepper
                activeStepIndex={activeStepIndex}
                steps={steps}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={this.hideModal}>
            Close
          </Button>
          {activeStepIndex > 0 && (
            <Button variant="secondary" type="button" onClick={this.prevStep} disabled={!selectedPromptId}>
              Previous
            </Button>
          )}
          {activeStepIndex < steps.length - 1 && (
            <Button variant="primary" type="button" onClick={this.nextStep} disabled={!selectedPromptId}>
              Next
            </Button>
          )}
          {activeStepIndex === steps.length - 1 && (
            <Button variant="primary" onClick={this.saveAnswer} disabled={!selectedPromptId || !selectedItemId}>
              Replace Answer
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  handlePromptCheck = (selectedPromptId: string) => { this.setState(prevState => ({ selectedPromptId: selectedPromptId === prevState.selectedPromptId ? undefined : selectedPromptId })); }

  handleAnswerCheck = (selectedItemId: string) => { this.setState(prevState => ({ selectedItemId: selectedItemId === prevState.selectedItemId ? undefined : selectedItemId })); }

  render() {
    const { currentUser, match: { params }, addNotification } = this.props;
    const { userId } = params;
    const wrapperUserId = `user:us-west-2:${userId}`;
    // if (!currentUser) return null;
    if (currentUser && currentUser.id !== wrapperUserId) return <Redirect to={`/taste/${currentUser && currentUser.id}/edit`} />;

    // const answers = (currentUser && currentUser.answers || []).filter(({ fromTasteTest }) => fromTasteTest);
    const { prompts, items, loading, selectedPromptId, selectedItemId, answers } = this.state;
    const selectedPrompt = prompts.find(({ id }) => id === selectedPromptId);
    let SHARE_URL: string | undefined;
    if (typeof (window) !== "undefined") {
      SHARE_URL = window.location.href.replace('/edit', '');
    }
    const steps = [
      {
        title: 'Select a Prompt',
        content: (
          <div>
            {differenceBy(prompts, answers, ({ id, type, prompt }) => type === 'answer' ? (prompt as any).id : id ).map(({ id, title, description, promptType }, i) => (
              <button type="button" key={id} className="btn mb-3" onClick={() => { this.handlePromptCheck(id); }} style={{ width: '100%' }}>
                <MediaObject
                  text={title}
                  subtext={description}
                  imageType="circle"
                  image={(
                    <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f7f7f7', height: '100%', width: '100%' }}>
                      {promptType === 'track' && <MusicalNote width={30} style={{ padding: 0, margin: 0 }} />}
                      {promptType === 'artist' && <Microphone width={40} style={{ padding: 0, margin: 0 }} />}
                    </div>
                  )}
                  right={<CheckCircle id={id} checked={id === selectedPromptId} onCheck={this.handlePromptCheck} />}
                />
              </button>
            ))}
          </div>
        ),
      },
      {
        title: 'Choose an Answer',
        content: (
          <div>
            <div className="mb-3 p-3 rounded d-flex justify-content-between align-items-center" style={{ border: '0.5px solid #e2e2e2' }}>
              <p style={{ padding: 0, margin: 0 }}>{selectedPrompt && (selectedPrompt as any).title}</p>
              <button type="button" className="btn pl-4 pr-3 d-flex justify-content-center align-items-center" onClick={() => { this.setState(({ activeStepIndex }) => ({ selectedPromptId: undefined, activeStepIndex: activeStepIndex - 1 })) }} style={{ borderLeft: '0.5px solid #e2e2e2' }}>
                <i className="fa fa-close" style={{ fontSize: 20 }} />
              </button>
            </div>
            <div className="mb-3">
              <Form.Control type="text" placeholder="Search Spotify..." autoFocus={true} onInput={(e: any) => { this.handleQueryUpdate(e.target.value, { limit: 15, offset: 0 }); }} />
            </div>
            <div>
              {items.map((item: any, i) => {
                const { id } = item;
                return (
                  <button type="button" key={id} className="btn mb-3" onClick={() => { this.handleAnswerCheck(id); }} style={{ width: '100%' }}>
                    <Item
                      item={item}
                      right={<CheckCircle id={id} checked={id === selectedItemId} onCheck={this.handleAnswerCheck} />}
                    />
                  </button>
                );
              })}
            </div>
          </div>
        ),
      },
    ];

    // for (let i = 0; i < MAX_NUMBER_OF_ANSWERS - answers.length; i++) {
    //   addAnswers.push(
    //     <button key={i} onClick={() => { this.showModal(ModalId.add) }} className="bm-Answer bm-Answer--blank bm-center_children">
    //       <h6 style={{ margin: 0 }}>Add an Answer</h6>
    //       <div className="btn bm-Answer__actionButton bm-Answer__actionButton--primary">
    //         <i className="icon ion-ios-add" style={{ fontSize: 28, display: 'flex' }} />
    //       </div>
    //     </button>
    //   );
    // }

    return (
      <AuthRoutes showNavbar={true} alwaysFillNavbar={true}>
        {/* <input id="shareUrl" value={SHARE_URL} readOnly /> */}
        <div className="container" style={{ paddingTop: 40, paddingBottom: 100, minHeight: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: 40 }}>
            <div style={{ flexGrow: 1 }}>
              <h2>Edit Your Taste Test</h2>
              <p>Answer up to 10 prompts and challenge your friends to see how well they know your taste in music</p>
            </div>
            <div className="d-flex">
              <Button href={`/taste/${currentUser && currentUser.id.split(':')[2]}/preview`} style={{ marginRight: 8 }}>Preview</Button>
              <Button
                variant="primary"
                dropdownItems={[
                  <Dropdown.Item
                    key="facebook"
                    href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${SHARE_URL}`}
                    target="_blank"
                  >
                    <i className="fa fa-facebook mr-3" style={{ fontSize: 16 }} />Share To Facebook
                  </Dropdown.Item>,
                  <Dropdown.Item
                    key="twitter"
                    href={`https://twitter.com/share?url=${SHARE_URL}`}
                    target="_blank"
                  >
                    <i className="fa fa-twitter mr-3" style={{ fontSize: 16 }} />Share To Twitter
                  </Dropdown.Item>,
                  <Dropdown.Item
                    key="copy"
                    onClick={() => {
                      const tempInput = document.createElement('input') as any;
                      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
                      tempInput.value = SHARE_URL;
                      document.body.appendChild(tempInput);
                      tempInput.select();
                      document.execCommand("copy");
                      document.body.removeChild(tempInput);
                      addNotification({ title: 'Copied!', message: 'The link has been copied to your clipboard.'});
                    }}
                  >
                    Copy link
                  </Dropdown.Item>,
                ]}
              >
                Share
              </Button>
            </div>
          </div>
          <Tabs id="tabs">
            <Tab eventKey="answers" title={`Your Answers (${answers.length}/${MAX_NUMBER_OF_ANSWERS})`}>
              {loading ? <Spinner /> : (
                <div className="d-flex flex-wrap">
                  {
                    answers.map(({ id, items = [], prompt }: any, i) => {
                      const item = items[0];
                      if (!item) return null;

                      return (
                        <div key={id} className="bm-Answer">
                          <h6 className="bm-Answer__promptTitle">{`${i+1}. ${prompt.title}`}</h6>
                          <Item item={item} />
                          <Button
                            className="btn bm-Answer__actionButton d-flex justify-content-center align-items-center"
                            dropdownStyle={{ position: 'absolute', bottom: -5, right: -5 }}
                            dropdownItems={[
                              <Dropdown.Item
                                key="update"
                                onClick={() => {
                                  this.setState({ replacementAnswerId: id, selectedPromptId: prompt.id, activeStepIndex: 1 });
                                  this.showModal(ModalId.update);
                                }}
                              >
                                Update
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key="replace"
                                onClick={() => {
                                  this.setState({ replacementAnswerId: id });
                                  this.showModal(ModalId.replace);
                                }}
                              >
                                Replace
                              </Dropdown.Item>,
                            ]}
                          >
                            <i className="icon ion-ios-close" style={{ fontSize: 28, display: 'flex' }} />
                          </Button>
                        </div>
                      );
                    })
                  }
                  {answers.length < MAX_NUMBER_OF_ANSWERS && (
                    <button onClick={() => { this.showModal(ModalId.add) }} className="bm-Answer bm-Answer--blank bm-center_children">
                      <h6 style={{ margin: 0 }}>Add an Answer</h6>
                      <div className="btn bm-Answer__actionButton bm-Answer__actionButton--primary">
                        <i className="icon ion-ios-add" style={{ fontSize: 28, display: 'flex' }} />
                      </div>
                    </button>
                  )}
                </div>
              )}
            </Tab>
            <Tab eventKey="friends" title="Your Top Friends">
              <ChartsTable userId={currentUser && currentUser.id} showBorder={true} />
            </Tab>
          </Tabs>
          {/* <h5 style={{ marginBottom: 16 }}>{`Your Answers (${answers.length}/${MAX_NUMBER_OF_ANSWERS})`}</h5>
          {loading ? <Spinner /> : (
            <div>
              {
                answers.map(({ id, items = [], prompt }: any) => {
                  const item = items[0];
                  if (!item) return null;

                  return (
                    <div key={id} className="bm-Answer">
                      <h6 className="bm-Answer__promptTitle">{prompt.title}</h6>
                      <Item item={item} />
                      <Button
                        className="btn bm-Answer__actionButton d-flex justify-content-center align-items-center"
                        dropdownStyle={{ position: 'absolute', bottom: -5, right: -5 }}
                        dropdownItems={[
                          <Dropdown.Item
                            key="update"
                            onClick={() => {
                              this.setState({ replacementAnswerId: id, selectedPromptId: prompt.id, activeStepIndex: 1 });
                              this.showModal(ModalId.update);
                            }}
                          >
                            Update
                          </Dropdown.Item>,
                          <Dropdown.Item
                            key="replace"
                            onClick={() => {
                              this.setState({ replacementAnswerId: id });
                              this.showModal(ModalId.replace);
                            }}
                          >
                            Replace
                          </Dropdown.Item>,
                        ]}
                      >
                        <i className="icon ion-ios-close" style={{ fontSize: 28, display: 'flex' }} />
                      </Button>
                    </div>
                  );
                })
              }
              {addAnswers}
            </div>
          )} */}
        </div>
        {this.renderAddModal(steps)}
        {this.renderUpdateModal(steps)}
        {this.renderReplaceModal(steps)}
        <Footer />
      </AuthRoutes>
    );
  }
}

const mapStateToProps = (state : ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: User) => { dispatch(updateCurrentUser(user)); },
  addNotification: (notification: Omit<Notification, 'id'>) => { dispatch(addNotification(notification)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTasteTest);