/* External dependencies */
import React from 'react'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

/* Internal dependencies */
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import './PostShareButtons.scss';

type OwnProps = {
  url?: string;
  showPinterest?: boolean;
  color?: boolean;
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & DispatchProps;

const PostShareButtons: React.FunctionComponent<Props> = ({ url, addNotification, showPinterest = true, color = false }) => {
  if (!url) {
    let SHARE_URL: string | undefined;
    if (typeof (window) !== 'undefined') {
      SHARE_URL = window.location.href;
      url = SHARE_URL;
    }
  }

  return (
    <div className="bm-Post_share mb-4 d-flex">
      <a className={`bm-Post__share_link bm-Post__share_link--facebook btn mr-3${color ? ' bm-Post__share_link--color' : ''}`} href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${url}`} target="_blank" rel="noopener noreferrer">
        <i className="fa fa-facebook" style={{ fontSize: 28 }} />
      </a>
      <a className={`bm-Post__share_link bm-Post__share_link--twitter btn mr-3${color ? ' bm-Post__share_link--color' : ''}`} href={`https://twitter.com/share?url=${url}`} target="_blank" rel="noopener noreferrer">
        <i className="fa fa-twitter" style={{ fontSize: 28 }} />
      </a>
      {showPinterest && (
        <a className={`bm-Post__share_link bm-Post__share_link--pinterest btn mr-3${color ? ' bm-Post__share_link--color' : ''}`} href={`http://pinterest.com/pin/create/button/?url=${url}`} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-pinterest" style={{ fontSize: 28 }} />
        </a>
      )}
      <button
        className={`bm-Post__share_link bm-Post__share_link--link btn${color ? ' bm-Post__share_link--color' : ''}`}
        onClick={() => {
          const tempInput = document.createElement('input') as any;
          tempInput.style = "position: absolute; left: -1000px; top: -1000px";
          tempInput.value = url;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
          addNotification({
            title: 'Copied!',
            message: 'The URL has been copied to your clipboard.',
            variant: 'secondary',
          });
        }}
      >
        <i className="fa fa-link" style={{ fontSize: 28 }} />
      </button>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => { dispatch(addNotification(notification)); },
});

export default connect(null, mapDispatchToProps)(PostShareButtons);