/* External dependencies */
import React from 'react';
import { Helmet } from 'react-helmet';

/* Internal dependencies */
import Button from '../button/Button';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './About.scss';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Beatmatch | About</title>
        <meta property="og:determiner" content="a" />
        <meta property="og:description" content="Beatmatch is a social platform for connecting people through music and technology." />
        <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/about`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Beatmatch | About" />
        <meta property="og:image" content={`http://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
        {/* <meta property="og:image:secure_url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:width" content="500" /> */}
        <meta property="og:site_name" content="Beatmatch" />
        <meta property="twitter:description" content="Beatmatch is a social platform for connecting people through music and technology." />
        <meta name="twitter:site" content="@beatmatchsocial" />
        <meta name="twitter:creator" content="@beatmatchsocial" />
        <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/about`} />
        <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
        <meta property="twitter:title" content="Beatmatch | About" />
        <meta property="twitter:card" content="summary" />
        <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}/about`} />
        <script type="application/ld+json">
          {
            `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}`}",
              "logo": "${`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`}"
            }
          `}
        </script>
      </Helmet>
      <Navbar showLogo={true} alwaysFillNavbar={false} />
      <div className="bg-white pb-5">
        <section className="bm-About_header bg-primary d-flex justify-content-center align-items-center p-5" style={{ marginTop: -75, minHeight: 475 }}>
          <div className="container d-flex justify-content-center">
            <div className="rounded d-flex justify-content-center p-5" style={{ overflow: 'hidden' }}>
              <h1 className="text-white text-center p-0 m-0">All About The Music</h1>
            </div>
          </div>
        </section>
        <section>
          <div className="bm-About_coverImage d-flex justify-content-center align-items-center container mt-5 pt-5 pb-5">
            <div className="bm-TheApp__header_image2 rounded" />
            <div className="bm-About_summaryContainer bg-white p-5" style={{ minHeight: 200, maxWidth: '60%', marginLeft: -200 }}>
              <h3 className="text-black"><b>Why Beatmatch Matters</b></h3>
              <p>Strong friendships and relationships are important for living a healthy, rewarding life. Beatmatch is a social platform for connecting people through music and technology. Your music taste speaks volumes about yourself. Everything from how you talk to how you dress is influenced by the type of music you listen to. Whether you're looking for your soulmate, making new friends, or finding artists to collaborate with, music is the common language we all speak.</p>
              <Link to="/the-beat" className="btn btn-primary">Read our blog</Link>
            </div>
          </div>
        </section>
        <section>
          <div className="bm-About_coverImage d-flex justify-content-center align-items-center container pb-5">
            <div className="bm-About_summaryContainer bg-white p-5" style={{ minHeight: 200, maxWidth: '60%', marginRight: -200 }}>
              <h3><b>Who Are We?</b></h3>
              <p>We are creatives, crate-digging DJs, and open-minded listeners all bonded by a shared love for music. What began as a simple revelation about how music has impacted our lives, has transformed into a mission to connect the world through music.</p>
              <Link to="/the-beat" className="btn btn-primary">Read our blog</Link>
            </div>
            <div className="bm-TheApp__header_image3 rounded" />
          </div>
        </section>
        {/* <section className="col-sm-12 d-flex justify-content-center align-items-center">
          <div className="bg-white p-5" style={{ minHeight: 200, maxWidth: '60%', marginRight: -200, position: 'relative' }}>
            <h2 className="text-black">Why Beatmatch?</h2>
            <p>Beatmatch connects people through music. We believe your music taste speaks volumes about yourself. It can impact everything from how you dress to how you talk and to how you have fun. Beatmatch now empowers users to meet someone with similar music taste and common interests to quickly spark a real connection.</p>
            <Button variant="primary" href="https://blog.beatmatchsocial.com">Learn more</Button>
          </div>
          <div className="bm-TheApp__header_image2 rounded" />
        </section> */}
      </div>
      <Footer />
    </>
  );
};

export default About;