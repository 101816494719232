/* External dependencies */
import React from 'react';

/* Internal dependencies */
import Item from '../item/Item';
import './Answer.scss';

type OwnProps = {
  answer: any;
  index?: number;
  right?: React.ReactNode;
};

type Props = OwnProps;


const Answer: React.SFC<Props> = ({ answer, index, right }) => {
  const { id, items = [], prompt } = answer;
  const item = items[0];
  if (!item) return null;

  return (
    <div key={id} className="bm-Answer d-flex justify-content-between align-items-center">
      <div>
        <h6 className="bm-Answer__promptTitle">{`${index !== undefined ? `${index+1}. ${prompt.title}` : prompt.title }`}</h6>
        <Item item={item} />
      </div>
      {right && (
        <div>
          {right}
        </div>
      )}
    </div>
  );
};

export default Answer;