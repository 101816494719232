/* External dependencies */
import React from 'react';
import { connect } from 'react-redux';

/* Internal dependencies */
import './App.scss';
import { ApplicationState } from './store';
import { User } from './types/User';
import { Dispatch } from 'redux';
import { CurrentUserState, updateCurrentUser, getCurrentUser, updateCurrentUserLoading } from './store/ducks/currentUser';
import Navbar from './navbar/Navbar';
import AuthModal from './authModal/AuthModal';
import { getSignedInStatus } from './App';

type OwnProps = {
  showNavbar?: boolean;
  children: React.ReactNode;
  alwaysFillNavbar?: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  updateCurrentUser(user: User): void;
  updateCurrentUserLoading(loading: CurrentUserState['loading']): void;
};

type Props = OwnProps & StateProps;

type State = {
  isSignedIn: boolean | undefined;
  loading: boolean;
  showAuthModal?: boolean;
};

class AuthRoutes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isSignedIn: undefined, loading: true, showAuthModal: undefined };
  }

  async componentDidMount() {
     await getSignedInStatus(); 
  }

  handleAuthModalOpen = () => {
    this.setState({ showAuthModal: true });
  };

  handleAuthModalClose = () => {
    this.setState({ showAuthModal: false });
  };

  handleSuccess = () => {
    this.setState(({ showAuthModal }) => ({ showAuthModal: showAuthModal === true && false }));
  };

  render() {
    const { children, currentUser, showNavbar = true, alwaysFillNavbar = false } = this.props;
    const { loading, showAuthModal } = this.state;

    return (
      <React.Fragment>
        {showNavbar && <Navbar alwaysFillNavbar={alwaysFillNavbar} />}
        {/* {loading && (<Spinner />)} */}
        {currentUser && children}
        <AuthModal show={showAuthModal !== undefined ? showAuthModal : !currentUser && !loading} onShow={this.handleAuthModalOpen} onClose={this.handleAuthModalClose} onSuccess={this.handleSuccess} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: CurrentUserState['user']) => { dispatch(updateCurrentUser(user)); },
  updateCurrentUserLoading: (loading: CurrentUserState['loading']) => { dispatch(updateCurrentUserLoading(loading)); },
});


export default connect(mapStateToProps, mapDispatchToProps)(AuthRoutes);