/* External dependencies */
import React, { ButtonHTMLAttributes } from 'react';
import { Button as BootstrapButton, ButtonProps, Dropdown, Spinner as BootstrapSpinner} from 'react-bootstrap';

/* Internal dependencies */
import './Button.scss';
import Spinner from '../spinner/Spinner';

type Props = ButtonHTMLAttributes<any> & ButtonProps & {
  loading?: boolean;
  dropdownClassName?: string;
  dropdownStyle?: any;
  dropdownItems?: React.ReactNode[];
  type?: ButtonHTMLAttributes<any>['type'];
};

const Button: React.SFC<Props> = ({ variant = 'secondary', dropdownClassName, dropdownStyle, dropdownItems, className, loading = false, children: outerChildren, type = 'button', ...props }) => {
  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: React.Ref<any>) => (
    <button
      ref={ref}
      className={className ? className : `btn btn-${variant} ${className}`}
      type={type}
      {...props}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  if (dropdownItems && dropdownItems.length) {
    return (
      <Dropdown className={dropdownClassName} alignRight={true} style={dropdownStyle}>
        <Dropdown.Toggle variant={variant} id="dropdown-basic" {...props} as={CustomToggle}>
          {outerChildren}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dropdownItems.map(item => item)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <BootstrapButton disabled={loading} className={`btn${` btn-${variant}`} ${className}`} {...props}>
      {!loading ? outerChildren : (
        <BootstrapSpinner className={variant === 'primary' ? 'text-white' : 'text-secondary'} animation="border" role="status">
          <span className="sr-only text-white">Loading...</span>
        </BootstrapSpinner>
      )}
    </BootstrapButton>
  );
}

export default Button;