/* External dependencies */
import { combineReducers } from 'redux';
import { get } from 'lodash';

/* Internal dependencies */
import { generateReducer, generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum PlayerActionType {
  UPDATE_TRACK = 'reducer/player/UPDATE_TRACK',
  UPDATE_STREAM_URL = 'reducer/player/UPDATE_STREAM_URL',
  // UPDATE_PLAYBACK_STATUS = 'reducer/player/UPDATE_PLAYBACK_STATUS',
}

export type PlayerState = {
  track: any | null;
  streamUrl: string;
  // playbackStatus: Expo.Audio.PlaybackStatus;
};

/* Selectors */
export const getPlayer = (state: ApplicationState) => state.player;
export const getTrack = (state: ApplicationState) => get(state, 'player.track');
export const getStreamUrl = (state: ApplicationState) => get(state, 'player.streamUrl');
// export const getPlaybackStatus = (state: ApplicationState) => get(state, 'player.playbackStatus');

/* Action creators */
export const updateTrack = generateActionCreator<PlayerActionType.UPDATE_TRACK, PlayerState['track']>(
  PlayerActionType.UPDATE_TRACK
);

export const updateStreamUrl = generateActionCreator<PlayerActionType.UPDATE_STREAM_URL, PlayerState['streamUrl']>(
  PlayerActionType.UPDATE_STREAM_URL
);

// export const updatePlaybackStatus = generateActionCreator<PlayerActionType.UPDATE_PLAYBACK_STATUS, PlayerState['playbackStatus']>(
//   PlayerActionType.UPDATE_PLAYBACK_STATUS
// );

/* Reducer */
const trackReducer = generateReducer<PlayerActionType.UPDATE_TRACK, PlayerState['track']>(
  PlayerActionType.UPDATE_TRACK,
  null,
);

const streamUrlReducer = generateReducer<PlayerActionType.UPDATE_STREAM_URL, PlayerState['streamUrl']>(
  PlayerActionType.UPDATE_STREAM_URL,
  '',
);

// const playbackStatusReducer = generateReducer<PlayerActionType.UPDATE_PLAYBACK_STATUS, PlayerState['playbackStatus']>(
//   PlayerActionType.UPDATE_PLAYBACK_STATUS,
//   {} as Expo.Audio.PlaybackStatus,
// );

const playerReducer = combineReducers({
  track: trackReducer,
  streamUrl: streamUrlReducer,
  // playbackStatus: playbackStatusReducer,
});

export default playerReducer;