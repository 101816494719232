/* External dependencies */
import React from 'react';
import { getFacebookAccessToken } from 'src/api/credentials';

type Props = {
  provider: 'facebook' | 'spotify';
};

class AuthLandingPage extends React.Component<Props> {
  async componentDidMount() {
    this.handleLanding();
  }

  handleLanding = async () => {
    const { provider } = this.props;
    const query = this.useQuery();
    const code = query.get('code') || '';
    const state = query.get('state') || '';

    // if (provider === 'facebook') {
    //   // const { accessToken: token, expiresIn: expires } = await getFacebookAccessToken(code, state, redirectURI);

    // } else if (provider === 'spotify') {
    //   await addSpotifyCredentialForUser(currentUser.id, code, state);
    // }
    window.close();
  };

  useQuery = () => {
    return new URLSearchParams(window.location.search);
  }

  render() {
    return null;
  }
}

export default AuthLandingPage;