/* External dependencies */
import React from 'react';

/* Internal dependencies */
import './Slideshow.scss';

type Props = {
  interval?: number;
  data: any[];
  renderSlide(data: any, index: number): React.ReactNode;
  dotColor?: string;
};

type State = {
  currentSlideIndex: number;
};

const DEFAULT_INTERVAL = 5000;

class Slideshow extends React.Component<Props, State> {
  slideInterval: any;
  carousel: any;

  constructor(props: any) {
    super(props);
    this.state = {
      currentSlideIndex: 0,
    };
  }

  componentDidMount() {
    const { interval = DEFAULT_INTERVAL } = this.props;
    if (typeof (window) !== "undefined") {
      this.slideInterval = window.setInterval(this.next, DEFAULT_INTERVAL);
      this.carousel = $('.carousel');

      if (this.carousel) {
        this.carousel.carousel({
          interval,
          wrap: true,
        })
      }
    }
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  next = () => {
    const { data } = this.props;
    if (this.carousel) {
      this.carousel.carousel('next');
    }
    this.setState(({ currentSlideIndex }) => ({ currentSlideIndex: (currentSlideIndex + 1) % data.length }));
  };

  prev = () => {
    const { data } = this.props;
    if (this.carousel) {
      this.carousel.carousel('prev');
    }
    this.setState(({ currentSlideIndex }) => ({ currentSlideIndex: (currentSlideIndex - 1) % data.length }));
  };

  jump = (index: number) => {
    const { data } = this.props;
    if (index >= 0 || index < data.length) {
      if (this.carousel) {
        this.carousel.carousel(index);
      }
      this.setState({ currentSlideIndex: index });
    }
  };

  clearInterval = () => {
    if (typeof (window) !== "undefined") {
      if (this.slideInterval) {
        window.clearInterval(this.slideInterval);
      }
    }
  };

  resetSlideInterval = () => {
    this.clearInterval();
    this.slideInterval = window.setInterval(this.next, DEFAULT_INTERVAL);
  }

  createOnDotClick = (slideIndex: number) => () => {
    this.jump(slideIndex);
    this.resetSlideInterval();
  };


  render() {
    const { data, renderSlide, dotColor = '#fff' } = this.props;
    const { currentSlideIndex } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
          <div className="carousel-inner">
            {data.map((item, index) => (
              <div key={index} className={`carousel-item ${currentSlideIndex === index ? 'active' : ''}`}>
                {renderSlide(item, index)}
              </div>
            ))}
          </div>
          <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon text-dark" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
            <span className="carousel-control-next-icon text-dark" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div style={{ position: 'absolute', bottom: 20, width: '100%' }}>
          <div className="container d-flex align-items-center">
            {data.map((_, index) => (<button key={index} onClick={this.createOnDotClick(index)} className={`btn bm-Slideshow__dot ${currentSlideIndex === index ? 'bm-Slideshow__dot--current' : ''} mr-2`} style={{ borderColor: dotColor }} />))}
          </div>
        </div> 
      </div>
    );
  }
}

export default Slideshow;