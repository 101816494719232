/* External dependencies */
import React from 'react';
import { match, withRouter } from 'react-router-dom';

/* Internal dependencies */
import Spinner from '../spinner/Spinner';
import { listAnswersForUser, removeAnswerForUser } from '../api/answers';
import Answer from '../answer/Answer';
import Button from '../button/Button';

type InjectedProps = {
  match: match<{
    userId: string;
  }>;
};

type OwnProps = {
  userId: string;
};

type Props = InjectedProps & OwnProps;

type State = {
  answers: any[];
  loading: boolean;
};

class ListAnswers extends React.Component<Props, State> {
  state = { answers: [], loading: true };

  async componentWillMount() {
    await this.load();
  }

  async componentDidUpdate({ userId }: any) {
    if (userId !== this.props.userId) {
      await this.load();
    }
  }

  load = async () => {
    try {
      this.setState({ loading: true });
      const { userId } = this.props;
      const { answers }: any = await listAnswersForUser(userId, false);
      this.setState({ answers });
    } catch (e) {
      console.log('e', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  createRemoveAnswer = (answerId: string) => async () => {
    const { match } = this.props;
    const { userId } = match.params;
    const { answers } = this.state;
    await removeAnswerForUser(userId, answerId);
    const answerIndex = answers.findIndex(({ id }) => id === answerId);
    if (answerIndex !== -1) {
      this.setState({
        answers: [
          ...answers.slice(0, answerIndex),
          ...answers.slice(answerIndex + 1),
        ],
      });
    }
  };

  render() {
    const { answers = [], loading } = this.state;

    if (loading) return <Spinner />;

    return (
      <div className="d-flex flex-column align-items-center">
        {answers.map((answer: any) => (
          <Answer
            key={answer.id}
            answer={answer}
            right={(
              <Button variant="danger" onClick={this.createRemoveAnswer(answer.id)}>Delete</Button>
            )}
          />
        ))}
      </div>
    );
  }
}

export default withRouter<any, any>(ListAnswers);