/* External dependencies */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import chunk from 'lodash/chunk';
import shuffle from 'lodash/shuffle';
import Typed from 'typed.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import './Music.scss';
import SpotifyPlaylist from '../music/SpotifyPlaylist';
import Spinner from '../spinner/Spinner';
import { getPlaylistsForSpotifyUser } from '../api/spotify';
import { listBeatmatchEvents } from '../api/events';
import { listPosts } from '../api/posts';
import Sanitize from 'src/sanitize/Sanitize';

type Props = any;
type State = {
  posts: any[];
  events: any[];
  playlists: any[];
  postsLoading: boolean;
  selectionIndexes: {
    [c: string]: number;
  };
};

const TWITCH_URL = 'https://www.twitch.tv/beatmatchsocial';
const BEATMATCH_SPOTIFY_ID = 'bakkvmm7qkhmy1nq5kk12naxh';

class Music extends React.Component<Props, State> {
  blogCarousel: React.RefObject<Carousel>;
  blogCarouselMobile: React.RefObject<Carousel>;
  eventsCarousel: React.RefObject<Carousel>;
  eventsCarouselMobile: React.RefObject<Carousel>;
  playlistsCarousel: React.RefObject<Carousel>;
  playlistsCarouselMobile: React.RefObject<Carousel>;
  words: string[];
  typed: any;

  constructor(props: Props) {
    super(props);
    this.blogCarousel = React.createRef<Carousel>();
    this.blogCarouselMobile = React.createRef<Carousel>();
    this.eventsCarousel = React.createRef<Carousel>();
    this.eventsCarouselMobile = React.createRef<Carousel>();
    this.playlistsCarousel = React.createRef<Carousel>();
    this.playlistsCarouselMobile = React.createRef<Carousel>();
    this.state = {
      posts: [],
      events: [],
      playlists: [],
      postsLoading: true,
      selectionIndexes: {
        blogCarousel: 0,
        blogCarouselMobile: 0,
        events: 0,
        eventsCarousel: 0,
        eventsCarouselMobile: 0,
        playlistsCarousel: 0,
        playlistsCarouselMobile: 0,
      },
    };
    this.words = shuffle([
      'is the language we all speak',
      'is a bridge',
      'brings us together',
      'heals',
      'is community',
    ]);
    this.words = ['is a love language', ...this.words];
  }

  async componentDidMount() {
    try {
      this.typed = new Typed('#headerWords', {
        strings: this.words,
        smartBackspace: true,
        shuffle: false,
        backDelay: 2000,
        typeSpeed: 135,
        backSpeed: 75,
        startDelay: 0,
        loop: true,
      });
      const [{ items: posts }, { items: events }, { items: playlists }] = await Promise.all([
        listPosts(15,0),
        listBeatmatchEvents(),
        getPlaylistsForSpotifyUser(BEATMATCH_SPOTIFY_ID),
      ]);
      this.setState({ posts, events, playlists: playlists.reverse() });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ postsLoading: false });
    }
  }

  renderBlogPost = ({ title, body, description, providerId, images, slug }: any, i: number) => (
    <div className="bm-Music_item" key={i} style={{ width: '100%', overflow: 'hidden' }}>
      {images && images[0] && (
        <div className="rounded d-flex justify-content-center align-items-center bm-Music_itemContainer mb-3" style={{ position: 'relative', height: 400, overflow: 'hidden' }}>
          <Link to={`/the-beat/${providerId}/${slug}`} className="bm-Music_image--scale btn rounded" rel="noopener noreferrer" style={{ maxHeight: 500, minHeight: 400, height: '100%', width: '100%', padding: 0 }}>
            <img
              className="rounded"
              src={images[0].url}
              width="100%"
              alt={slug}
              style={{
                flexShrink: 0,
                objectFit: 'cover',
                height: '100%',
                width: '100%',
              }}
            />
          </Link>
        </div>
      )}
      <div className="d-flex flex-column align-items-center">
        {title ? <h4>{title}</h4> : <h4>{(body as string).split(' ').slice(0,5).filter(str => !str.startsWith('#') && !str.startsWith('http')).join(' ')}...</h4>}
        {description && <Sanitize html={description.replace(/(https?:\/\/\S+)/, '')} style={{ maxHeight: 75, overflow: 'hidden', textOverflow: 'ellipsis' }} />}
        <Link to={`/the-beat/${providerId}/${slug}`}>Read more</Link>
      </div>
    </div>
  );

  renderEvent = ({ name, description, date, endDate, images = [], externalUrl }: any, i: number) => {
    const momentDate = moment(date);
    const start = new Date(date);
    const end = new Date(endDate);
    const currentDate = new Date();
    const isLive = currentDate > start && currentDate < end;

    return (
      <div className="ml-2 mr-2" key={i} style={{ width: '100%' }}>
        <div className="mb-3 rounded d-flex justify-content-center align-items-center bm-Music_itemContainer" style={{ position: 'relative', height: 400, overflow: 'hidden' }}>
          <a aria-label={`Read more about the "${name}" event`} className="bm-Music_image--scale btn rounded" target="_blank" rel="noopener noreferrer" href={isLive ? TWITCH_URL : externalUrl} style={{padding: 0, maxHeight: 500, minHeight: 400, width: '100%', border: 'none' }}>
            <img
              className="rounded"
              alt={`${name} event`}
              src={images[0].url}
              width="100%"
              style={{
                flexShrink: 0,
                minWidth: '100%',
                objectFit: 'cover',
                minHeight: 400,
              }}
            />
          </a>
          <div className="d-flex flex-column justify-content-center align-items-center rounded bm-Music_eventDate p-2" style={{ position: 'absolute', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.75)', minWidth: 100, minHeight: 100 }}>
            <h4 className="text-white text-bold" style={{ fontSize: 24 }}>{momentDate.format('MMM').toUpperCase()}</h4>
            <h4 className="text-white text-bold m-0" style={{ fontSize: 24 }}>{momentDate.format('D').toUpperCase()}</h4>
            {isLive && <h4 className="p-2 mt-2 text-white rounded" style={{ backgroundColor: '#e84118' }}>LIVE</h4>}
          </div>
        </div>
        <h4 className="mb-2 text-center">{name}</h4>
        <p className="text-center">{description}</p>
      </div>
    );
  };

  handleCarouselChange = (carouselName: string) => (index: number) => {
    this.setState(({ selectionIndexes }) => ({ selectionIndexes: { ...selectionIndexes, [carouselName]: index } }));
  };

  isDisabled = (carouselName: string, mode: 'prev' | 'next', items: any[]) => {
    const { selectionIndexes } = this.state;
    return mode === 'prev' ? selectionIndexes[carouselName] < 1 : selectionIndexes[carouselName] >= items.length - 1;
  };

  render() {
    const { posts = [], events = [], playlists: spotifyPlaylists = [], postsLoading, selectionIndexes } = this.state;
    const chunkedPosts = chunk(posts, 3);
    const chunkedEvents = chunk(events, 3);
    // BLOG POSTS
    const blogPosts = chunkedPosts.map((chunk, i) => (
      <div key={i} className="d-none d-md-flex justify-content-between" style={{ width: '100%', marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
        {chunk.map(this.renderBlogPost)}
        {chunk.length < 3 && (
          <div key="empty-state" style={{ width: '100%' }} />
        )}
      </div>
    )) as any;
    const blogPostsMobile = posts.map((post, i) => (
      <div key={post.id} className="d-md-none d-flex" style={{ width: '100%', marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
        {this.renderBlogPost(post, i)}
      </div>
    )) as any;

    // EVENTS
    const beatmatchEvents = chunkedEvents.map((chunk, i) => (
      <div key={i} className="d-none d-md-flex justify-content-center" style={{ width: '100%', marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
        {chunk.map(this.renderEvent)}
        {chunk.length < 2 && (
          <div key="empty-state" style={{ width: '100%' }} />
        )}
      </div>
    )) as any;
    const beatmatchEventsMobile = events.map((event, i) => (
      // TODO: replace index with event ID as key when hooked up to Facebook event data
      <div key={i} className="d-md-none d-flex" style={{ width: '100%', marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
        {this.renderEvent(event, i)}
      </div>
    )) as any;

    // PLAYLISTS
    const spotifyChunk = chunk(spotifyPlaylists, 3);
    const playlists = spotifyChunk.map((chunk, i) => (
      <div key={i} className="d-flex" style={{ width: '100%', marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
        {chunk.map(({ providerId, name, description, images }) => {
          const image = images.sort((a: any, b: any) => a.width - b.width)[0];
              
          return (
            <div key={providerId} className="rounded justify-content-center ml-2 mr-2" style={{ width: '100%', overflow: 'hidden', flex: 1 }}>
              <SpotifyPlaylist id={providerId} name={name} image={image.url} />
              <h4>{name}</h4>
              <p>{description.replace('&#x27;', `'`)}</p>
            </div>
          );
        })}
        {chunk.length < 3 && (
          <div key="empty-state" style={{ width: 400 }} />
        )}
      </div>
    ));
    const playlistsMobile = spotifyPlaylists.map(({ providerId, name, description, images }) => {
      const image = images.sort((a: any, b: any) => a.width - b.width)[0];

      return (
        <div key={providerId} className="d-md-none d-flex" style={{ width: '100%', marginLeft: '-0.5rem', marginRight: '-0.5rem', overflow: 'hidden' }}>
          <div className="rounded d-flex flex-column align-item-center bm-Music_itemContainer" style={{ position: 'relative', minHeight: 400, overflow: 'hidden'  }}>
            <SpotifyPlaylist id={providerId} name={name} image={image.url} />
            <div>
              <h4>{name}</h4>
              <p>{description.replace('&#x27;', `'`)}</p>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Beatmatch | Social platform for connecting people through music and technology</title>
          <meta property="og:determiner" content="a" />
          <meta property="description" content="Beatmatch is a social platform for connecting people through music and technology." />
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Beatmatch | Social platform for connecting people through music and technology" />
          <meta property="og:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="og:description" content="Beatmatch is a social platform for connecting people through music and technology." />
          <meta name="twitter:site" content="@beatmatchsocial" />
          <meta name="twitter:creator" content="@beatmatchsocial" />
          <meta property="twitter:description" content="Beatmatch is a social platform for connecting people through music and technology." />
          <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`} />
          <meta property="twitter:title" content="Beatmatch" />
          <meta property="twitter:card" content="summary" />

          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
        </Helmet>
        <Navbar showLogo={true} alwaysFillNavbar={false} />
        <div className="bg-white pb-5">
          <section className="bm-Music__header_image1 d-flex flex-column justify-content-center align-items-center p-5 mb-5" style={{ marginTop: -75, minHeight: 475 }}>
            <div className="container d-flex flex-column justify-content-center align-items-center pt-2" style={{ height: '100%' }}>
              <h1 className="text-center text-white">Music <b id="headerWords"></b></h1>
            </div>
          </section>
          <section className="container mb-5">
            <div className="d-flex justify-content-between mb-4">
              <h2>The Beat</h2>
              <div className="d-none d-md-flex">
                <button aria-label="Previous" className="btn d-none d-md-block" disabled={this.isDisabled('blogCarousel', 'prev', blogPosts)} onClick={() => { this.blogCarousel.current!.decrement()}}><i className="fa fa-chevron-left" /></button>
                <button aria-label="Next" className="btn d-none d-md-block" disabled={this.isDisabled('blogCarousel', 'next', blogPosts)} onClick={() => { this.blogCarousel.current!.increment()}}><i className="fa fa-chevron-right" /></button>
              </div>
              <div className="d-md-none d-flex">
                <button aria-label="Previous" className="btn" disabled={this.isDisabled('blogCarouselMobile', 'prev', blogPostsMobile)} onClick={() => { this.blogCarouselMobile.current!.decrement()}}><i className="fa fa-chevron-left" /></button>
                <button aria-label="Next" className="btn" disabled={this.isDisabled('blogCarouselMobile', 'next', blogPostsMobile)} onClick={() => { this.blogCarouselMobile.current!.increment()}}><i className="fa fa-chevron-right" /></button>
              </div>
            </div>
            {postsLoading ? <Spinner /> : (
              <>
                <Carousel
                  ref={this.blogCarousel}
                  className="bm-Music_carousel d-none d-md-block"
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={selectionIndexes['blogCarousel']}
                  onChange={this.handleCarouselChange('blogCarousel')}
                >
                  {blogPosts}
                </Carousel>
                <Carousel
                  ref={this.blogCarouselMobile}
                  className="bm-Music_carousel d-md-none d-flex justify-content-center"
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={selectionIndexes['blogCarouselMobile']}
                  onChange={this.handleCarouselChange('blogCarouselMobile')}
                >
                  {blogPostsMobile}
                </Carousel>
              </>
            )}
          </section>
          <section className="container mb-5">
            <div className="d-flex justify-content-between mb-4">
              <h2>Events</h2>
              <div className="d-none d-md-flex">
                <button aria-label="Previous" className="btn d-none d-md-block" disabled={this.isDisabled('eventsCarousel', 'prev', beatmatchEvents)} onClick={() => { this.eventsCarousel.current!.decrement()}}><i className="fa fa-chevron-left" /></button>
                <button aria-label="Next" className="btn d-none d-md-block" disabled={this.isDisabled('eventsCarousel', 'next', beatmatchEvents)} onClick={() => { this.eventsCarousel.current!.increment()}}><i className="fa fa-chevron-right" /></button>
              </div>
              <div className="d-md-none d-flex">
                <button aria-label="Previous" className="btn" disabled={this.isDisabled('eventsCarouselMobile', 'prev', beatmatchEventsMobile)} onClick={() => { this.eventsCarouselMobile.current!.decrement()}}><i className="fa fa-chevron-left" /></button>
                <button aria-label="Next" className="btn" disabled={this.isDisabled('eventsCarouselMobile', 'next', beatmatchEventsMobile)} onClick={() => { this.eventsCarouselMobile.current!.increment()}}><i className="fa fa-chevron-right" /></button>
              </div>
            </div>
            {postsLoading ? <Spinner /> : (
              <>
                <Carousel
                  ref={this.eventsCarousel}
                  className="bm-Music_carousel d-none d-md-block"
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={selectionIndexes['eventsCarousel']}
                  onChange={this.handleCarouselChange('eventsCarousel')}
                >
                  {events.length === 0 ? <p className="p-5">Oh no! There are no upcoming events scheduled. We're always working to bring you new experiences so check again soon!</p> : beatmatchEvents}
                </Carousel>
                <Carousel
                  ref={this.eventsCarouselMobile}
                  className="bm-Music_carousel d-md-none d-block"
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={selectionIndexes['eventsCarouselMobile']}
                  onChange={this.handleCarouselChange('eventsCarouselMobile')}
                >
                  {events.length === 0 ? <div className="d-flex justify-content-center" style={{ width: '100vw' }}><p className="p-5">Oh no! There are no upcoming events scheduled. We're always working to bring you new experiences so check again soon!</p></div> : beatmatchEventsMobile}
                </Carousel>
              </>
            )}
          </section>
          <section className="container mb-5">
            <div className="d-flex justify-content-between mb-4">
              <h2>Playlists</h2>
              <div className="d-none d-md-flex">
                <button aria-label="Previous" className="btn" disabled={this.isDisabled('playlistsCarousel', 'prev', playlists)} onClick={() => { this.playlistsCarousel.current!.decrement()}}><i className="fa fa-chevron-left" /></button>
                <button aria-label="Next" className="btn" disabled={this.isDisabled('playlistsCarousel', 'next', playlists)} onClick={() => { this.playlistsCarousel.current!.increment()}}><i className="fa fa-chevron-right" /></button>
              </div>
              <div className="d-md-none d-flex">
                <button aria-label="Previous" className="btn" disabled={this.isDisabled('playlistsCarouselMobile', 'prev', playlistsMobile)} onClick={() => { this.playlistsCarouselMobile.current!.decrement()}}><i className="fa fa-chevron-left" /></button>
                <button aria-label="Next" className="btn" disabled={this.isDisabled('playlistsCarouselMobile', 'next', playlistsMobile)} onClick={() => { this.playlistsCarouselMobile.current!.increment()}}><i className="fa fa-chevron-right" /></button>
              </div>
            </div>
            {postsLoading ? <Spinner /> : (
              <>
                <Carousel
                  ref={this.playlistsCarousel}
                  className="bm-Music_carousel d-none d-md-block"
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={selectionIndexes['playlistsCarousel']}
                  onChange={this.handleCarouselChange('playlistsCarousel')}
                >
                  {playlists}
                </Carousel>
                <Carousel
                  ref={this.playlistsCarouselMobile}
                  className="bm-Music_carousel d-md-none d-block"
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={selectionIndexes['playlistsCarouselMobile']}
                  onChange={this.handleCarouselChange('playlistsCarouselMobile')}
                >
                  {playlistsMobile}
                </Carousel>
              </>
            )}
          </section>
        </div>
        <Footer />
      </>
    );
  }
};

export default Music;
