/* External dependencies */
import { createStore, combineReducers, Store } from 'redux';

/* Internal dependencies */
import currentUserReducer, { CurrentUserState } from './ducks/currentUser';
import notificationsReducer, { NotificationsState } from './ducks/notifications';
import playerReducer, { PlayerState } from './ducks/player';

export type ApplicationState = {
  currentUser: CurrentUserState;
  notifications: NotificationsState;
  player: PlayerState;
};

/** Combined set of all reducers. */
export const reducers = combineReducers<ApplicationState>({
  currentUser: currentUserReducer,
  notifications: notificationsReducer,
  player: playerReducer,
});

const store: Store = createStore(
  reducers,
  typeof (window) !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;