

/* External dependencies */
import React from 'react';
import { Image } from 'react-bootstrap';

/* Internal dependencies */
import './SpotifyPlaylist.scss';

type OwnProps = {
  id: string;
  name: string;
  image: string;
};

type Props = OwnProps;

type State = {
  showPlaylist: boolean;
};

class SpotifyPlaylist extends React.Component<Props, State> {
  state = { showPlaylist: false };

  handleCoverClick = () => {
    this.setState({ showPlaylist: true });
  }

  render() {
    const { id, name, image } = this.props;
    const { showPlaylist } = this.state;

    return (
      <div className="d-flex flex-column justify-content-center" style={{ minHeight: 400, position: 'relative' }}>
        <iframe
          title={`${name} Spotify Playlist`}
          src={`https://open.spotify.com/embed/playlist/${id}`}
          className="bm-Music_playlist d-flex m-0 mb-3 rounded"
          width="100%"
          height="400"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
          style={{padding: 0, visibility: showPlaylist ? 'visible' : 'hidden' }}
        />
        <div style={{ overflow: 'hidden', position: 'absolute', top: -1, left: 0, display: !showPlaylist ? 'block' : 'none'}}>
          <button onClick={this.handleCoverClick} className="bm-Music_image--scale btn rounded" style={{ position: 'relative', maxHeight: 500, padding: 0 }}>
            <Image className="rounded" src={image} width="100%" alt={name} fluid style={{
                flexShrink: 0,
                margin: 0,
                padding: 0,
                minWidth: 400,
                objectFit: 'cover',
                minHeight: 400,
                maxHeight: 400,
              }} />
            <div className="d-flex justify-content-center align-items-center" style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              <div className="d-flex justify-content-center align-items-center" style={{ height: 80, width: 80, borderRadius: 40, overflow: 'hidden', padding: 0 }}>
                <i className="material-icons" style={{ backgroundColor: '#96f', fontSize: 100, color: '#fff', opacity: 0.95, height: 100, width: 100 }}>play_circle_filled</i>
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }
}

export default SpotifyPlaylist;