/* External dependencies */
import React from 'react';

/* Internal dependencies */
import Track from '../track/Track';
import Artist from '../artist/Artist';

type OwnProps = { 
  item: any | any;
  right?: React.ReactNode;
};

type Props = OwnProps;

const Item: React.SFC<Props> = ({ item, right }) => {
  const { type } = item;
  if (type === 'track') return <Track track={item} right={right} />;
  if (type === 'artist') return <Artist artist={item} right={right} />;
  return null;
};

export default Item;
