/* External dependencies */
import React from 'react';

/* Internal dependencies */
import Button from '../button/Button';
import './CheckCircle.scss';

type Props = {
  id: string;
  checked: boolean;
  color?: 'primary' | 'success';
  onCheck?: (id: string) => void;
  useAsButton?: boolean;
};

const CheckCircle: React.SFC<Props> = ({ id, checked, onCheck, color = 'primary', useAsButton = false }) => {
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    width: 35,
    borderRadius: 35 / 2,
    border: checked ? 'none' : '1px solid rgba(0,0,0,0.3)',
    padding: 0,
  };

  if (useAsButton) {
    return (
      <button
        type="button"
        onClick={() => { onCheck && onCheck(id); }}
        className={`btn${checked ? ` bg-${color}` : ''}`}
        style={styles}
      >
        {checked && <i className="icon ion-md-checkmark text-white" style={{ fontSize: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
      </button>
    );
  }

  return (
    <div className={`btn${checked ? ` bg-${color}` : ''}`} style={styles}>
      {checked && <i className="icon ion-md-checkmark text-white" style={{ fontSize: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
    </div>
  );
};

export default CheckCircle;