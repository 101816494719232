/* External dependencies */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBDCRzfch_cwQEE07AtInv4k1UYvesWs_c',
  authDomain: 'beatmatch-2e6e9.firebaseapp.com',
  projectId: 'beatmatch',
  storageBucket: 'beatmatch.appspot.com',
  messagingSenderId: '866114988367',
  appId: '1:866114988367:web:5e87fa03dc6703c3157d3a',
  measurementId: 'G-TRP43G51ZQ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);