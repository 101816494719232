/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { modelUser } from '../store/helpers/users';
import { cleanObject } from '../common/cleanObject';
import { PaginationOptions } from '../types/Pagination';

export const listMatchesForUser = async (userId: string, { limit = 15, offset }: PaginationOptions) => {
  const response = await API.get(ApiName.bmApp, `/users/${userId}/matches`, {
    queryStringParameters: {
      limit,
      offset: Boolean(offset) && JSON.stringify(offset) || undefined,
    },
  });

  response.matches = response.matches.map((match: any) => ({
    ...match,
    user: modelUser(match.user),
  }));

  return response;
};

export const searchMatchesForUser = async (userId: string, q: string, { limit = 15, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users/${userId}/matches`, {
    queryStringParameters: cleanObject({
      q,
      limit,
      offset: Boolean(offset) && JSON.stringify(offset) || undefined,
    }),
  });

export const removeMatchForUser = async (userId: string, matchId: string) =>
  await API.del(ApiName.bmApp, `/users/${userId}/matches/${matchId}`, undefined);