/* External dependencies */
import React from 'react'
import { match, Redirect, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment';
import sanitize from 'sanitize-html';
import { Image } from 'react-bootstrap';

/* Internal dependencies */
import './Post.scss';
import { getPost, listPosts } from 'src/api/posts';
import Spinner from 'src/spinner/Spinner';
import Footer from 'src/footer/Footer';
import Navbar from 'src/navbar/Navbar';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import MediaObject from 'src/mediaObject/MediaObject';
import Sanitize from 'src/sanitize/Sanitize';
import PostShareButtons from './PostShareButtons';
import Subscribe from 'src/subscribe/Subscribe';
import Intro from '../assets/images/welcome-to-beatmatch.png';

type InjectedProps = {
  match: match<{
    postId: string;
  }>;
};

type Props = InjectedProps;

type State = {
  post: any | undefined;
  relatedPosts: any[];
  loading: boolean;
};

class Post extends React.Component<Props, State> {
  state = { post: undefined, relatedPosts: [], loading: true };

  async componentDidMount() {
    await this.loadPost();
  }
  
  async componentDidUpdate({ match }: Props) {
    if (match.params.postId !== this.props.match.params.postId) {
      await this.loadPost();
    }
  }

  loadPost = async () => {
    const { match: { params } } = this.props;
    const { postId } = params;

    try {
      this.setState({ loading: true });
      const [post, { items: relatedPosts }] = await Promise.all([
        getPost(postId),
        listPosts(5),
      ]);
      var d = document, s = d.createElement('script');
      s.src = 'https://beatmatch.disqus.com/embed.js';
      s.setAttribute('data-timestamp', + new Date() as any);
      (d.head || d.body).appendChild(s);
      this.setState({ post, relatedPosts });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { post = {}, relatedPosts, loading } = this.state;
    const { id, title, body, date, subType, images, slug, providerId, tags = [] } = post! as any;
    const momentDate = date && moment(date);
    const metaTitle = `Beatmatch | ${title || 'The Beat'}`;
    const metaUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/the-beat/${providerId}/${slug}`;
    const metaImage = images && images[0] && (images[0] as any).url || `https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`;
    let SHARE_URL: string | undefined;
    if (typeof (window) !== "undefined") {
      SHARE_URL = window.location.href;
    }

    return (
      <div className="bg-white" style={{ minHeight: '100vh' }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle}</title>
          <meta name="description" content={title} />
          {tags.length && <meta name="keywords" content={tags.join(',')} />}
          <meta property="og:description" content={title} />
          <meta property="og:url" content={metaUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:determiner" content="a" />
          <meta property="og:type" content="article" />
          <meta property="og:article:published_time" content={date} />
          <meta property="og:article:modified_time" content={date} />
          <meta property="og:article:author" content="Beatmatch Editors" />
          <meta property="og:article:section" content="The Beat" />
          {tags.map((tag: string) => <meta property="og:article:tag" content={tag} />)}
          <meta property="og:title" content={metaTitle} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:alt" content={slug} />
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content="Meet people around you with similar music tastes." />
          <meta name="twitter:site" content="@beatmatchsocial" />
          <meta name="twitter:creator" content="@beatmatchsocial" />
          <meta property="twitter:url" content={metaUrl} />
          <meta property="twitter:image" content={metaImage} />
          <meta property="twitter:image:alt" content={slug} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:card" content="summary" />
      
          <link rel="canonical" href={metaUrl} />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${metaUrl}"
                },
                "headline": "${title}",
                "image": ["${metaImage}"],
                "datePublished": "${date}",
                "dateModified": "${date}",
                "author": {
                  "@type": "Organization",
                  "name": "Beatmatch",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`}"
                  }
                },
                 "publisher": {
                  "@type": "Organization",
                  "name": "Beatmatch",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`}"
                  }
                }
              }
            `}
          </script>
        </Helmet>
        <Navbar showLogo={true} />
        {loading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}><Spinner /></div> : (
          <section className="bm-Post_wrapper d-flex justify-content-between container pt-5 pb-5">
            <section className="bm-Post">
              {<h2 className="bm-Post_title mb-2">{title}</h2>}
              {momentDate && <p className="mb-3">Posted {momentDate.fromNow()}</p>}
              <PostShareButtons />
              <div className="bm-Post_container d-flex flex-column align-items-start mb-5">
                {subType !== 'text' && images && images[0] && (
                  <div className="rounded mb-3 d-flex flex-column align-items-start">
                    <div className="rounded d-flex flex-column align-items-start">
                      <img
                        className="rounded"
                        src={images && (images[0] as any).url}
                        height="auto"
                        width="100%"
                        alt={slug}
                        style={{
                          flexShrink: 0,
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                )}
                {body && <Sanitize html={body} className="bm-Post_body d-flex flex-column align-items-start mb-1" />}
              </div>
              <div id="disqus_thread" style={{ width: '100%' }}></div>
            </section>
            <section className="bm-Post_rightColumn">
              <div className="mb-5">
                {/* <h3 className="text-bold mb-3">Join waitlist now</h3> */}
                <a href="/" target="_blank"><Image className="mb-3" src={Intro} rounded fluid width="100%" /></a>
                <Subscribe showLabel={false} buttonText="Join the party" />
              </div>
              <div>
                <h3 className="text-bold mb-3">You may also like</h3>
                {relatedPosts.filter(({ id: postId }) => id !== postId).map(({ providerId, title, images, slug, date }) => (
                  <Link to={`/the-beat/${providerId}/${slug}`} className="btn m-0 mb-3 p-0 d-flex flex-wrap" style={{ maxHeight: 1000 }}>
                    <MediaObject
                      text={title}
                      subtext={`Posted ${moment(date).fromNow()}`}
                      image={images && images[0] && (images[0] as any).url}
                    />
                  </Link>
                ))}
              </div>
            </section>
          </section>
        )}
        {!loading && !post && <Redirect to="/not-found" />}
        <Footer />
      </div>
    );
  }
}

export default Post;