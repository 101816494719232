/* External dependencies */
import React from 'react';
import get from 'lodash/get';

/* Internal dependencies */
import MediaObject from '../mediaObject/MediaObject';

type OwnProps = { 
  artist: any;
  right?: React.ReactNode;
};

type Props = OwnProps;

const Artist: React.SFC<Props> = ({ artist, right }) => {
  return (
    <MediaObject
      text={artist.name}
      imageType="circle"
      image={get(artist, 'images[0].url', '')}
      right={right}
    />
  );
};

export default Artist;
