/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';

export const listPosts = async (limit: number = 10, offset: number = 0) =>
  await API.get(ApiName.bmApp, `/posts`, {
    queryStringParameters: { limit, offset },
  });

export const getPost = async (id: string) =>
  await API.get(ApiName.bmApp, `/posts/${id}`, null);