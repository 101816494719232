/* External dependencies */
import React from 'react';
import { Button, Modal, Form, ButtonProps } from 'react-bootstrap';

/* Internal dependencies */
import SubscribeModal from './SubscribeModal';
import './SubscribeButton.scss';
import { logEvent } from '@firebase/analytics';
import { analytics } from 'src/firebase';

type OwnProps = ButtonProps & {
  // variant?: 'primary' | 'success';
  text?: string;
  color?: string;
  backgroundColor?: string;
};

type Props = OwnProps;

class SubscribeButton extends React.Component<Props> {
  state = { open: false };

  showModal = () => {
    this.setState({ open: true });
    logEvent(analytics, 'waitlist_openModal', this.state);
  };

  hideModal = () => {
    this.setState({ open: false });
  };

  join = async () => {
    // async call
    this.hideModal();
    // show success flash
  };

  render() {
    const { variant, text = 'GET EARLY ACCESS', color = '#fff', backgroundColor } = this.props;
    const { open } = this.state;

    return (
      <>
        <Button
          variant={variant}
          onClick={this.showModal}
          style={{ width: '100%', borderRadius: 30, fontWeight: 700, height: 150, color, backgroundColor }}
        >
          {text}
        </Button>
        <SubscribeModal show={open} onShow={this.showModal} onHide={this.hideModal} />
      </>
    );
  }
}

export default SubscribeButton;
