/* External dependencies */
import React from 'react';
import { Image } from 'react-bootstrap';
import get from 'lodash/get';
import { withRouter, match } from 'react-router-dom';

/* Internal dependencies */
import { listResultsForUser } from '../api/results';
import DefaultImage from '../assets/images/default-image.jpg';
import Spinner from '../spinner/Spinner';

type InjectedProps = {
  match: match<{ userId: string; resultId: string; }>;
  history: any;
};

type Props = InjectedProps & {
  userId: string;
  showBorder: boolean;
};

type State = {
  results: any[];
  loading: boolean;
};

class ChartsTable extends React.Component<Props, State> {
  state = { results: [], loading: true };
  
  async componentDidMount() {
    const { userId } = this.props;
    try {
      const { results = [] }: any = await listResultsForUser(userId, { limit: 10 });
      this.setState({ results });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { userId, history, match: { params }, showBorder = false } = this.props;
    const { resultId: pageResultId } = params;
    const { results = [] } = this.state;
    const wrappedPageResultId = `result:${pageResultId}`;
    const isEdit = showBorder;

    return (
      <div className="container">
        <div style={{ height: '100%', width: '100%' }}>
          {results.map(({ user, score, id: resultId }, i) => (
            <div
              className={`${resultId === wrappedPageResultId ? 'mb-4 rounded' : 'rounded'}`}
              style={{
                border: resultId === wrappedPageResultId ? '5px solid #fff' : undefined,
                padding: resultId === wrappedPageResultId ? 5 : undefined,
              }}
            >
              <button className={`btn d-flex justify-content-between align-items-center bg-white p-4 rounded ${resultId === wrappedPageResultId ? '' : 'mb-4'}`}
                onClick={() => {
                  if (typeof (window) !== "undefined") {
                    history.push(`/taste/${(userId)}/results/${resultId}`);
                    window.scrollTo(0, 0);
                  }
                }}
                style={{
                  width: '100%',
                  maxHeight: 10000,
                  border: resultId === wrappedPageResultId && !isEdit ? '5px solid #fff' : isEdit ? '0.5px solid #e2e2e2' : undefined,
                }}
              >
                <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                  <div className="d-flex align-items-center">
                    <span className="d-flex mr-3 pr-3" style={{ width: '7%', borderRight: '1px solid #f7f7f7' }}>{i+1}.</span>
                    <div className="d-flex align-items-center mr-5">
                      <Image className="mr-3" src={get(user, 'images[0].url', DefaultImage)} height={40} width={40} fluid={true} roundedCircle style={{ height: 40, width: 40 }} />
                      <span className="d-flex mr-3">{user && (user as any).name || '--'}</span>
                      {i === 0 && <i className="fa fa-trophy" style={{ color: '#f1c40f', fontSize: 22 }} />}
                    </div>
                  </div>
                  <span style={{ fontWeight: 600 }}>{score}%</span>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter<any, any>(ChartsTable);