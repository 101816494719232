/* External dependencies */
import { API } from 'aws-amplify';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { PaginationOptions } from '../types/Pagination';

export const addResultForUser = async (userId: string, { score, responses, answers }: { score: number; responses: any[], answers: any[] }) => 
  await API.post(ApiName.bmApp, `/users-results/${userId}/results`, {
    body: { score, responses, answers },
  });

export const listResultsForUser = async (userId: string, { limit, offset }: PaginationOptions): Promise<any[]> =>
  await API.get(ApiName.bmApp, `/users-results/${userId}/results`, {
    queryStringParameters: {
      limit,
      offset,
    },
  });

export const getResultForUser = async (userId: string, resultId: string) =>
  await API.get(ApiName.bmApp, `/users-results/${userId}/results/${resultId}`, null);