/* Export dependencies */
import React from 'react';
import Typed from 'typed.js';
import shuffle from 'lodash/shuffle';
import { Image } from 'react-bootstrap';
import { withRouter, match } from 'react-router';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import Main from '../assets/images/main.png';
import SyncPreview from '../assets/images/sync-preview.png';
import MatchPreview from '../assets/images/match-preview.png';
import MeetPreview from '../assets/images/meet-preview.png';
import Player from '../assets/images/player.png';
import Event from '../assets/images/event.png';
import Conversation from '../assets/images/conversation.png';
import StreamingServices from '../assets/images/streaming-services.png';
import PromptAnswer from '../assets/images/prompt-answer.png';
import Chat from '../assets/images/chatting.png';
import Spotify from '../assets/images/spotify.svg';
import AppleMusic from '../assets/images/apple-music.svg';
import MusicLoverRadar from '../assets/images/music-lover-radar.png';
import WaitlistMember1 from '../assets/images/waitlist-member-1.jpeg';
import WaitlistMember2 from '../assets/images/waitlist-member-2.jpeg';
import WaitlistMember3 from '../assets/images/waitlist-member-3.jpeg';
import WaitlistMember4 from '../assets/images/waitlist-member-4.jpeg';
import WaitlistMember5 from '../assets/images/waitlist-member-5.jpeg';
import WaitlistMember6 from '../assets/images/waitlist-member-6.jpeg';
import WaitlistMember7 from '../assets/images/waitlist-member-7.jpeg';
import WaitlistMember8 from '../assets/images/waitlist-member-8.jpeg';
import WaitlistMember9 from '../assets/images/waitlist-member-9.jpeg';
import WaitlistMember10 from '../assets/images/waitlist-member-10.jpeg';
import { ReactComponent as Microphone } from '../assets/images/mic.svg';
import { ReactComponent as AuthenticConnections } from '../assets/images/authentic-connections.svg';
import { ReactComponent as Tickets } from '../assets/images/tickets-icon.svg';
import Navbar from '../navbar/Navbar';
import '../App.scss';
import './Home.scss';
import { ReactComponent as Bolt } from '../assets/images/bolt.svg';
import { ReactComponent as BoltWhite } from '../assets/images/bolt-white.svg';
import Footer from 'src/footer/Footer';
import Slideshow from 'src/slideshow/Slideshow';
import Subscribe from 'src/subscribe/Subscribe';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import { getLaunchWaitlist } from 'src/api/waitlists';
import { ReactComponent as WarnerMusic } from '../assets/images/warner-music-group.svg';
import { ReactComponent as QualityControlMusic } from '../assets/images/quality-control-music.svg';
import { ReactComponent as SonyMusic } from '../assets/images/sony-music.svg';
import { ReactComponent as HYBEMusic } from '../assets/images/hybe-music.svg';
import { ReactComponent as RightHandMusic } from '../assets/images/right-hand-music-group.svg';
import { ReactComponent as ConcordMusic } from '../assets/images/concord-music.svg';
import { ReactComponent as TechstarsMusic } from '../assets/images/techstars-music.svg';

type InjectedProps = any & {
  match: match<{
    userId: string;
  }>;
  history: any;
};

type OwnProps = {
  spotify?: boolean;
};

type Props = InjectedProps & OwnProps;

type State = {
  currentSlideIndex: number;
  waitlistTotal: number | undefined;
  waitlistLoading: boolean;
};

const featureSlides = [
  {
    heading: 'Match based on music you stream',
    subheading: <span>Not feeling R&B this month? No problem; we adapt to your listening preferences. The more music you stream, the better your recommendations get.</span>,
    image: StreamingServices,
    backgroundColor: '#fff',
  },
  {
    heading: 'Start conversations through music',
    subheading: 'Personalize your profile with music-themed prompts to get more dates. Highlight your favorite songs, artists, and albums to spark a conversation.',
    image: PromptAnswer,
    backgroundColor: '#F2ECEC',
  },
  {
    heading: 'Find your concert buddy',
    subheading: 'Never miss out on a show again. We show you concerts and music festivals based on your listening history and help you to find someone to go with! This is the perfect first date for music lovers.',
    image: Chat,
    backgroundColor: '#DFDEDA',
  },
];

class Home extends React.Component<Props, State> {
  artists: any[];
  typed: any;
  slideInterval: any;
  images: string[];

  constructor(props: any) {
    super(props);
    this.artists = ['music', ...shuffle([
      'KAYTRANADA',
      'Drake',
      'Kanye West',
      'Kehlani',
      'GoldLink',
      'The Weeknd',
      'Beyonce',
      'Anderson .Paak',
      'Masego',
      'Jhene Aiko',
      'Rihanna',
    ])];
    this.images = shuffle([
      WaitlistMember1,
      WaitlistMember2,
      WaitlistMember3,
      WaitlistMember4,
      WaitlistMember5,
      WaitlistMember6,
      WaitlistMember7,
      WaitlistMember8,
      WaitlistMember9,
      WaitlistMember10,
    ]);
    this.state = {
      currentSlideIndex: 0,
      waitlistTotal: undefined,
      waitlistLoading: true,
    };
  }

  async componentDidMount() {
    if (typeof (window) !== "undefined") {
      this.slideInterval = window.setInterval(() => { this.setState(({ currentSlideIndex }) => ({ currentSlideIndex: (currentSlideIndex + 1) % 4 })); }, 5000);
    }

    try {
      const { total } = await getLaunchWaitlist();
      this.setState({ waitlistTotal: total });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ waitlistLoading: false });
    }
  }

  componentWillUnmount() {
    // this.typed.destroy();
    if (typeof (window) !== "undefined") {
      window.clearInterval(this.slideInterval);
    }
  }

  handleAuthSuccess = () => {
    const { match: { params }, history } = this.props;
    const { userId } = params;
    history.push(`/taste/${(userId)}/edit`);
  };

  resetSlideInterval = () => {
    if (typeof (window) !== 'undefined') {
      if (this.slideInterval) {
        window.clearInterval(this.slideInterval);
      }

      this.slideInterval = window.setInterval(() => { this.setState(({ currentSlideIndex }) => ({ currentSlideIndex: (currentSlideIndex + 1) % 4 })); }, 5000);
    }
  }

  createOnDotClick = (slideIndex: number) => () => { this.setState({ currentSlideIndex: slideIndex }, () => { this.resetSlideInterval(); }) };

  render() {
    const { spotify = false } = this.props;
    const { currentSlideIndex, waitlistTotal, waitlistLoading } = this.state;

    return (
      <div className="bg-white">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Beatmatch | Dating for music lovers</title>
          <meta property="og:determiner" content="a" />
          <meta property="og:description" content="Beatmatch is the dating app helping music lovers find relationships by matching people with similar music tastes." />
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Beatmatch | Dating for music lovers" />
          <meta property="og:image" content={`http://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/welcome-to-beatmatch.png`} />
          {/* <meta property="og:image:secure_url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
          <meta property="og:image:height" content="500" />
          <meta property="og:image:width" content="500" /> */}
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content="Beatmatch is the dating app helping music lovers find relationships by matching people with similar music tastes." />
          <meta name="twitter:site" content="@beatmatchsocial" />
          <meta name="twitter:creator" content="@beatmatchsocial" />
          <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/welcome-to-beatmatch.png`} />
          <meta property="twitter:title" content="Beatmatch | Dating for music lovers" />
          <meta property="twitter:card" content="summary" />
          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <script type="application/ld+json">
            {
              `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}`}",
                "logo": "${`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`}"
              }`
            }
          </script>
          <script type="application/ld+json">
            {
              `{
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "Beatmatch",
                "operatingSystem": "IOS",
                "applicationCategory": "SocialNetworkingApplication",
                "applicationSubCategory": "DatingApplication"
              }`
            }
            </script>
          <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1328aa7a8f414acc4aaad2634/467d56da5fc744cb331e4ee25.js");`}</script>
        </Helmet>
        <Navbar alwaysFillNavbar={false} />
        {/* <section className="bm-TheApp__header_image bm-TheApp__header_image1">
          <div className="bm-container container bm-center_children">
            <div className="row">
              <div className="col-sm-12 bm-center_children bm-Home__sectionHalf">
                <Logo className="mb-5" fill="#fff" style={{ minWidth: 300 }} />
                <h1 className="text-center mb-5">Meet someone who loves <b id="headerArtist"></b></h1>
                <button className="btn">
                  <img src={IOSAppStore} width={200} />
                </button>
              </div>
            </div>
          </div>
        </section> */}
        <section className="bm-Home__bg bm-Music__header_image1 d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
          <div data-aos="fade-in" data-aos-duration="1750" style={{ marginLeft: 175 }}>
            <Bolt stroke="#fff" opacity={0.35} />
          </div>
          <div data-aos="fade-in" data-aos-duration="1750" style={{ marginLeft: 175, position: 'absolute', left: 20 }}>
            <BoltWhite stroke="#fff" opacity={0.5} />
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
            <div className="d-flex justify-content-start align-items-end container" style={{ height: '100%' }}>
              <div className="bm-ViewTaste__leftSide pt-3 pb-3 d-flex justify-content-between" style={{ flex: 4 }}>
                <div data-aos="fade-in" data-aos-disable="mobile" className="bm-Home_header flex-column justify-content-start d-none d-lg-flex">
                  <h1 className="bm-Home_heading pt-3 mb-4 text-white text-bold">Dating for music lovers.</h1>
                  <h3 className="bm-Home_subheading mb-3 text-white"><p className="lead">Vibe together with people who share your music taste.</p></h3>
                  <div className="d-none d-md-block">
                    <div style={{ width: '75%' }}>
                      <div className="mb-4" style={{ width: '100%' }}>
                        <Subscribe showLabel={false} buttonText="Join the party" buttonVariant="success" />
                      </div>
                      {!waitlistLoading && (
                        <div className="d-flex justify-content-start align-items-center rounded">
                          <div className="d-flex mr-3">
                            {this.images.slice(0,3).map((image: string, i) => <Image key={i} src={image} style={{ zIndex: this.images.length - i , height: 45, width: 45, borderRadius: '50%', objectFit: 'cover', marginLeft: i === 0 ? 0 : -8, border: '2px solid #fff' }} />)}
                          </div>
                          {waitlistTotal ? (
                            <h6
                              className="text-white m-0"
                            >
                              Join <span className="text-bold" style={{ fontWeight: 800 }}>{`${Math.floor(waitlistTotal / 50) * 50}+`}</span> music lovers already waiting to meet you!
                            </h6>
                          ): (
                            <h6
                              className="text-white m-0"
                            >
                              Join hundreds of music lovers already on the waitlist!
                            </h6>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex d-lg-none justify-content-center" style={{ position: 'absolute', bottom: -250, left: 0, width: '100%' }}>
                    <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 0 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Main} style={{ position: 'absolute', bottom: 0, zIndex: 10, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 1 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Player} style={{ position: 'absolute', bottom: 0, zIndex: 20, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 2 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Event} style={{ position: 'absolute', bottom: 0, zIndex: 30, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 3 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Conversation} style={{ position: 'absolute', bottom: 0, zIndex: 40, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                    </div>
                  </div>
                  {/* <a href="#" style={{ justifyContent: 'flex-start' }}>
                    <img src={IOSAppStore} width={200} />
                  </a> */}
                </div>
                <div className="bm-Home_header flex-column justify-content-start d-flex d-lg-none">
                  <h1 className="bm-Home_heading pt-3 mb-4 text-white text-bold">Dating for music lovers.</h1>
                  <h3 className="bm-Home_subheading mb-3 text-white"><p className="lead">Match based on the music you stream. Just press play.</p></h3>
                  <div className="d-none d-md-block">
                    <div style={{ width: '75%' }}>
                      <div className="mb-4" style={{ width: '100%' }}>
                        <Subscribe showLabel={false} buttonText="Join the party" buttonVariant="success" />
                      </div>
                      {!waitlistLoading && (
                        <div className="d-flex justify-content-start align-items-center rounded">
                          <div className="d-flex mr-3">
                            {this.images.slice(0,3).map((image: string, i) => <Image key={i} src={image} style={{ zIndex: this.images.length - i , height: 45, width: 45, borderRadius: '50%', objectFit: 'cover', marginLeft: i === 0 ? 0 : -8, border: '2px solid #fff' }} />)}
                          </div>
                          {waitlistTotal ? (
                            <h6
                              className="text-white m-0"
                            >
                              Join <span className="text-bold" style={{ fontWeight: 800 }}>{`${Math.floor(waitlistTotal / 50) * 50}+`}</span> music lovers already waiting to meet you!
                            </h6>
                          ): (
                            <h6
                              className="text-white m-0"
                            >
                              Join hundreds of music lovers already on the waitlist!
                            </h6>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex d-lg-none justify-content-center" style={{ position: 'absolute', bottom: -250, left: 0, width: '100%' }}>
                    <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 0 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Main} style={{ position: 'absolute', bottom: 0, zIndex: 10, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 1 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Player} style={{ position: 'absolute', bottom: 0, zIndex: 20, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 2 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Event} style={{ position: 'absolute', bottom: 0, zIndex: 30, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                      <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 3 ? 'bm-ViewTaste__slide--current' : ''}`} fluid={true} height={500} width={500} src={Conversation} style={{ position: 'absolute', bottom: 0, zIndex: 40, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                    </div>
                  </div>
                  {/* <a href="#" style={{ justifyContent: 'flex-start' }}>
                    <img src={IOSAppStore} width={200} />
                  </a> */}
                </div>
              </div>
              <div data-aos="fade-in" className="bm-ViewTaste__rightSide flex-column justify-content-end align-items-between" style={{ flex: 3, height: '100%', overflow: 'hidden' }}>
                <div className="ml-5 d-lg-block d-xl-block" style={{ position: 'relative', maxWidth: '60%', marginBottom: '-8rem' }}>
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 0 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={500} width={500} src={Main} style={{ position: 'absolute', bottom: 0, zIndex: 10, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 1 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={500} width={500} src={Player} style={{ position: 'absolute', bottom: 0, zIndex: 20, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 2 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={500} width={500} src={Event} style={{ position: 'absolute', bottom: 0, zIndex: 30, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                  <Image className={`bm-ViewTaste__slide ${currentSlideIndex === 3 ? 'bm-ViewTaste__slide--current' : ''} d-none d-lg-block d-xl-block`} fluid={true} height={500} width={500} src={Conversation} style={{ position: 'absolute', bottom: 0, zIndex: 40, display: 'block', height: 'auto', width: 'auto', marginBottom: '-1rem', borderRadius: 40, maxWidth: '34vh' }} />
                </div>
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-between align-items-center pb-3 mr-5" style={{ width: '5rem' }}>
                    <button onClick={this.createOnDotClick(0)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 0 ? 'bm-ViewTaste__dot--current' : ''}`} />
                    <button onClick={this.createOnDotClick(1)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 1 ? 'bm-ViewTaste__dot--current' : ''}`} />
                    <button onClick={this.createOnDotClick(2)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 2 ? 'bm-ViewTaste__dot--current' : ''}`} />
                    <button onClick={this.createOnDotClick(3)} className={`btn bm-ViewTaste__dot ${currentSlideIndex === 3 ? 'bm-ViewTaste__dot--current' : ''}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: 'rgb(25 25 25)', height: '20vh', position: 'relative', width: '100%' }}>
          <h5 className="text-white bm-Home_pressText">SYNCS WITH</h5>
          <div style={{ width: '100%' }}>
            <div className="container d-flex justify-content-center align-items-center flex-wrap">
              <Image className={`bm-Home_pressLogo${!Boolean(spotify) ? ' mr-5' : ''}`} src={Spotify} fluid />
              {!Boolean(spotify) && <Image className="bm-Home_pressLogo ml-5" src={AppleMusic} fluid />}
            </div>
            <div className="d-flex d-md-none justify-content-center container" style={{ width: '100%', position: 'absolute', top: -20, zIndex: 1000 }}>
              <SubscribeButton variant="success" text="JOIN THE PARTY" />
            </div>
          </div>
        </section>
        <section className="mb-5" style={{ backgroundColor: 'rgb(25 25 25)' }}>
          <div className="container" style={{ height: '100%', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <div data-aos="fade-in" className={`container d-flex justify-content-between align-items-center flex-wrap`} style={{ display: 'block', paddingBottom: '3rem', width: '100%' }}>
                <div className="bm-Home__bannerImage bm-Home__bannerImage--large">
                  <Image src={MusicLoverRadar} width="100%" fluid />
                </div>
                <div data-aos="fade-in" className="bm-Home__bannerDescription">
                  <h1 className="text-bold text-white mb-4">Play music. Find love.</h1>
                  <p className="text-white">Dating is hard. Especially when <b>bad music taste</b> is a <Link to="/the-beat/673112183809490944/your-music-taste-will-make-or-break-your-dating" target="_blank"><b>deal breaker</b></Link>. With Beatmatch, we do the work for you. You play music; we find your match.</p>
                  <SubscribeButton variant="primary" text="GET STARTED" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container bm-center_children bg-white d-flex flex-column align-items-center" style={{ height: '100%', width: '100%' }}>
            <h1 className="text-center pt-5">Stop endlessly swiping.</h1>
            <h1 className="text-center mb-5">Get real results.</h1>
            <div className="d-flex justify-content-between flex-wrap">
              <div data-aos="fade-in" className="d-flex flex-column align-items-center mb-4" style={{ flex: 1 }}> 
                <div className="bm-center_children bm-Home__sectionHalf rounded mb-4" style={{ maxWidth: 350, maxHeight: 200 }}>
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ borderRadius: 175, width: 200, height: 200 }}>
                    <AuthenticConnections width="75%" />
                  </div>
                </div>
                <div>
                  <h4 className="text-center"><b>Authentic Connections</b></h4>
                  <p className="text-center">Music is a part of your social identity; it influences everything from your beliefs to your mood. Now, you can make real connections based 100% on music compatibility.</p>
                </div>
              </div>
              <div data-aos="fade-in" className="d-flex flex-column align-items-center mb-4" style={{ flex: 1 }}> 
                <div className="bm-center_children bm-Home__sectionHalf rounded mb-4" style={{ maxWidth: 350, maxHeight: 200 }}>
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ borderRadius: 175, width: 200, height: 200 }}>
                    <Microphone width="35%" />
                  </div>
                </div>
                <div>
                  <h4 className="text-center"><b>Vibrant Conversations</b></h4>
                  <p className="text-center">Say goodbye to boring intros, repetitive small talk, and ghosting. Create genuine conversations using the power of music. Share new songs, up-and-coming artists, and throwback albums to bond with your potential dates.</p>
                </div>
              </div>
              <div data-aos="fade-in" className="d-flex flex-column align-items-center mb-4" style={{ flex: 1 }}> 
                <div className="bm-center_children bm-Home__sectionHalf rounded  mb-4" style={{ maxWidth: 350, maxHeight: 200 }}>
                  {/* <img className="img img-fluid" src={Screenshot} style={{ maxHeight: 200 }} /> */}
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ borderRadius: 175, width: 200, height: 200 }}>
                    <Tickets width="55%" />
                  </div>
                </div>
                <div>
                  <h4 className="text-center"><b>Better Dates</b></h4>
                  <p className="text-center">Swiping doesn't start relationships; concert dates do. <Link to="/the-beat/673112183809490944/your-music-taste-will-make-or-break-your-dating" target="_blank">81% of people</Link> who go to a concert as a first date, go on a second date. Quit swiping and finally meet your concert buddy.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-5" style={{ backgroundColor: '#271845' }}>
          <div className="container bm-center_children" style={{ height: '100%', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <div data-aos="fade-in" className={`container d-flex justify-content-between align-items-center flex-wrap`} style={{ display: 'block', paddingTop: '3rem', paddingBottom: '3rem', width: '100%' }}>
                <div className="bm-Home__bannerImage bm-Home__bannerImage--large">
                  <iframe
                    width="100%"
                    height={400}
                    src="https://www.youtube.com/embed/b3hkPlKhYec?controls=0"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="rounded"
                  />
                </div>
                <div data-aos="fade-in" className="bm-Home__bannerDescription">
                  <div className="bm-Home_bannerDescription--center-mobile mb-4">
                    {this.images.slice(this.images.length - 5, this.images.length).map((image: string, i) => <Image key={i} src={image} style={{ zIndex: this.images.length - i , height: 65, width: 65, borderRadius: '50%', objectFit: 'cover', marginLeft: i === 0 ? 0 : -8, border: '2px solid #fff' }} />)}
                  </div>
                  <h2 className="text-bold text-white">Music is our love language.</h2>
                  <>
                    <p className="text-white">{waitlistTotal ? (
                        <span>And we're not alone. Join <span className="text-bold" style={{ fontWeight: 800 }}>{`${Math.floor(waitlistTotal / 50) * 50}+`}</span> music lovers already on the waitlist!</span>
                      ) : (
                      <span>And we're not alone. Join hundreds of music lovers already on the waitlist!</span>
                      )} Limited spots available. Reserve your spot before it's too late!
                    </p>
                  </>
                  <SubscribeButton variant="primary" text="JOIN THE COMMUNITY" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container" style={{ height: '100%', width: '100%' }}>
            <h1 className="text-bold text-center pt-5 mb-4">The end-to-end experience for music lovers.</h1>
            <h2 className="text-bold text-center mb-5">Discover cool people online then <i>actually</i> meet IRL.</h2>
            <div className="d-flex justify-content-between flex-wrap">
              <div data-aos="fade-in" className="bm-Home__card d-flex flex-column align-items-center ml-2 mr-2"> 
                <div className="bm-center_children bm-Home__sectionHalf rounded bg-primary" style={{ overflow: 'hidden', width: '100%' }}>
                  <Image fluid={true} src={SyncPreview} style={{ width: '100%' }} />
                </div>
                <div>
                  <h3 className="text-bold text-center" style={{ fontWeight: 600 }}>Sync</h3>
                  {Boolean(spotify) && <p className="text-center">Automatically sync your music preferences from <b>Spotify</b> to see recommended people and events near you. No long questionnaires; just your authentic self!</p>}
                  {!Boolean(spotify) && <p className="text-center">Automatically sync your music preferences from <b>Spotify</b> or <b>Apple Music</b> to see recommended people and events near you. No long questionnaires; just your authentic self!</p>}
                </div>
              </div>
              <div data-aos="fade-in" className="bm-Home__card d-flex flex-column align-items-center ml-2 mr-2"> 
                <div className="bm-center_children bm-Home__sectionHalf rounded bg-primary" style={{ overflow: 'hidden', width: '100%' }}>
                  <Image fluid={true} src={MatchPreview} style={{ width: '100%' }} />
                </div>
                <div>
                  {/* <div style={{ marginBottom: 32 }}>
                    <SpotifyLogo style={{ width: 125, height: 'auto', marginRight: 32 }} />
                    <img src={AppleMusicLogo} style={{ width: 125, height: 'auto' }} />
                  </div> */}
                  <h3 className="text-bold text-center" style={{ fontWeight: 600 }}>Match</h3>
                  <p className="text-center">See someone on the same wavelength? Send a like to see if the feeling's mutual. Break the ice by commenting on songs, artists, or upcoming concerts from their profile!</p>
                </div>
              </div>
              <div data-aos="fade-in" className="bm-Home__card d-flex flex-column align-items-center ml-2 mr-2"> 
                <div className="bm-center_children bm-Home__sectionHalf rounded bg-primary" style={{ overflow: 'hidden', width: '100%' }}>
                  <Image fluid={true} src={MeetPreview} style={{ width: '100%' }} />
                </div>
                <div>
                  {/* <div style={{ marginBottom: 32 }}>
                    <SpotifyLogo style={{ width: 125, height: 'auto', marginRight: 32 }} />
                    <img src={AppleMusicLogo} style={{ width: 125, height: 'auto' }} />
                  </div> */}
                  <h3 className="text-bold text-center" style={{ fontWeight: 600 }}>Meet</h3>
                  <p className="text-center">Don’t spend countless hours texting; just meet in real life. Browse recommended concerts and music festivals, see who's interested, invite someone special, and buy tickets in minutes!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="d-flex flex-column justify-content-center align-items-center pt-5 pb-5" style={{ backgroundColor: 'rgb(25 25 25)', position: 'relative', width: '100%' }}>
          <h3 data-aos="fade-in" className="text-white bm-Home_pressText text-bold text-center">POWERED BY THE BEST IN MUSIC</h3>
          <div data-aos="fade-in" style={{ width: '100%' }}>
            <div className="bm-Home_investors container d-flex justify-content-between align-items-center">
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <WarnerMusic fill="#fff" />
              </div>
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <QualityControlMusic fill="#fff" />
              </div>
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <SonyMusic fill="#fff" />
              </div>
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <HYBEMusic fill="#fff" />
              </div>
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <RightHandMusic fill="#fff" />
              </div>
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <ConcordMusic fill="#fff" />
              </div>
              <div className="bm-Home_investorLogo d-flex justify-content-center align-items-center mr-5">
                <TechstarsMusic fill="#fff" />
              </div>
            </div>
            {/* <div className="d-flex d-md-none justify-content-center container" style={{ width: '100%', position: 'absolute', top: -20, zIndex: 1000 }}>
              <SubscribeButton variant="success" text="JOIN THE PARTY" />
            </div> */}
          </div>
        </section>
        <section className="d-flex flex-column justify-content-center">
          <div data-aos="fade-in">
            <Slideshow
              data={featureSlides}
              dotColor="#9966ff"
              renderSlide={({ heading, subheading, image, backgroundColor }, i) => (
                <div key={i} className="pt-2 pb-3" style={{ width: '100%', backgroundColor }}>
                  <div className="container d-flex justify-content-between align-items-center flex-wrap" style={{ display: 'block', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <div className="bm-Home__bannerImage">
                      <Image src={image} width="100%" rounded={true} />
                    </div>
                    <div className="bm-Home__bannerDescription">
                      <h2 className="text-bold text-dark">{heading}</h2>
                      <p className="text-dark">{subheading}</p>
                      <SubscribeButton variant="primary" text="GET EARLY ACCESS" />
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          {/* {featureSlides.map(({ backgroundColor, image, heading, subheading }, i) => (
            <div style={{ width: '100%', backgroundColor }}>
              <div className={`bm-Home_banner container d-flex justify-content-between align-items-center flex-wrap ${i%2 === 0 ? 'flex-row-reverse' : '' }`} style={{ display: 'block', paddingTop: '3rem', paddingBottom: '3rem', width: '100%' }}>
                <div className="bm-Home__bannerImage" style={{ marginRight: i%2 === 0 ? 0 : '4.5rem', marginLeft: i%2 === 0 ? '4.5rem' : undefined }}>
                  <Image src={image} width="100%" rounded={true} />
                </div>
                <div className="bm-Home__bannerDescription">
                  <h2 className="text-bold text-dark">{heading}</h2>
                  <p className="text-dark">{subheading}</p>
                </div>
              </div>
            </div>
          ))} */}
        </section>
        {/* <section className="mb-5">
          <div className="container d-flex justify-content-between align-items-center flex-wrap">
            <iframe
              className="mr-5"
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/FtntspPrNf8"
              title="YouTube video player"
              frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ flex: 1, borderRadius: 10 }}
            />
            <div className="mb-3" style={{ flex: 1 }}>
              <h2 className="text-bold text-dark">Music is a love language</h2>
              <p className="text-dark">Your music taste speaks volumes about yourself! <b>98%</b> of daters say they have similarities in music tastes to their partner. Let music do the vetting for you!</p>
              <div style={{ width: '70%' }}>
                <SubscribeButton variant="primary" />
              </div>
            </div>
          </div>
        </section> */}
        {/* <section>
          <div className="bm-About_coverImage d-flex justify-content-center align-items-center container pb-5">
            <div className="bm-TheApp__header_image2 rounded" />
            <div className="bm-About_summaryContainer bg-white p-5" style={{ minHeight: 200, maxWidth: '60%', marginLeft: -200 }}>
              <h3 className="text-black"><b>Why Beatmatch Matters</b></h3>
              <p>Strong friendships and relationships are important for living a healthy, rewarding life. Beatmatch is a social platform for connecting people through music and technology. Your music taste speaks volumes about yourself. Everything from how you talk to how you dress is influenced by the type of music you listen to. Whether you're looking for your soulmate, making new friends, or finding artists to collaborate with, music is the common language we all speak.</p>
              <Link to="/the-beat" className="btn btn-primary">Read our blog</Link>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    );
  }
}

export default withRouter(Home);