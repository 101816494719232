/* External dependencies */
import React from 'react';

/* Internal dependencies */
import Fandom from './Fandom';
import MusicLoverRadar from '../assets/images/music-lover-radar-beliebers.png';
import DiscoverScreenshot from '../assets/images/discover-screenshot-beliebers.png';
import MusicScreenshot from '../assets/images/music-screenshot-beliebers.png';
import EventScreenshot from '../assets/images/event-screenshot-beliebers.png';
import WaitlistMember3 from '../assets/images/waitlist-member-3.jpeg';
import WaitlistMember4 from '../assets/images/waitlist-member-4.jpeg';
import WaitlistMember5 from '../assets/images/waitlist-member-5.jpeg';
import WaitlistMember6 from '../assets/images/waitlist-member-6.jpeg';
import WaitlistMember10 from '../assets/images/waitlist-member-10.jpeg';
import SuccessImage from '../assets/images/happy-april-fools-beiber.png';

const Beliebers: React.FC = () => {
  return (
    <Fandom
      fandom="BELIEBERS"
      radarImage={MusicLoverRadar}
      radarTitle="Play Beiber. Find love."
      radarButtonText="GET STARTED"
      title="THE BELIEBERS DATING APP"
      headerBackgroundClass="bm-Fandom__header_image--beliebers"
      headerDescription={(
        <>
          <p className="mb-4">Meet fans who love Justin Bieber as much as you do</p>
          <p className="text-primary text-underline"><i>You must get your peaches down in Georgia to join</i></p>
        </>
      )}
      headerQuote={'"YOU GOT THAT YUMMY YUM"'}
      headerButtonText="JOIN THE BELIEBERS"
      artistName="Justin Bieber"
      firstSectionTitle="Match with Beliebers"
      firstSectionDescription="You are paired 100% based on music compatibility! No fake Bieber fans allowed."
      firstSectionImage={DiscoverScreenshot}
      secondSectionTitle="Discover new music"
      secondSectionDescription="Need some new tunes? Share your favorites songs with other mega fans!"
      secondSectionImage={MusicScreenshot}
      thirdSectionTitle="Never miss a show"
      thirdSectionDescription="Browse upcoming Justin Bieber concerts, find out who wants to go, and invite someone for a date!"
      thirdSectionImage={EventScreenshot}
      firstBannerTitle="You're all that matters"
      firstBannerDescription="No more singing Justin Bieber in your room by yourself. Now you can vibe together with fans in your area."
      firstBannerButtonText="FIND LOVE"
      secondBannerTitle="Go and love yourself"
      secondBannerDescription="Don't settle for average music taste. Get someone who matches your wavelength!"
      secondBannerButtonText="LEVEL UP"
      playlistId="37i9dQZF1DXc2aPBXGmXrt"
      slug="beliebers"
      successImage={SuccessImage}
      productHuntBadge={(
        <a
          href="https://www.producthunt.com/posts/beliebers-dating-app?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-beliebers&#0045;dating&#0045;app" target="_blank"
        >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338803&theme=light" alt="Beliebers&#0032;Dating&#0032;App - Meet&#0032;fans&#0032;who&#0032;love&#0032;Justin&#0032;Bieber&#0032;as&#0032;much&#0032;as&#0032;you&#0032;do&#0033; | Product Hunt"
              style={{ width: 225, height: 40 }}
              width={225}
              height={40}
            />
        </a>
      )}
      waitlistImages={[
        WaitlistMember3,
        WaitlistMember4,
        WaitlistMember5,
        WaitlistMember6,
        WaitlistMember10,
      ]}
    />
  );
};

export default Beliebers;