/* External dependencies */
import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import MediaObject from '../mediaObject/MediaObject';
import Spinner from '../spinner/Spinner';
import Button from '../button/Button';
import { listMatchesForUser } from '../api/matches';

type OwnProps = {
  userId: string;
};

type Props = OwnProps;

type State = {
  matches: any[];
  loading: boolean;
};

class ListMatches extends React.Component<Props, State> {
  state = { matches: [], loading: true };

  async componentWillMount() {
    await this.load();
  }

  async componentDidUpdate({ userId }: any) {
    if (userId !== this.props.userId) {
      await this.load();
    }
  }

  load = async () => {
    try {
      this.setState({ loading: true });
      const { userId } = this.props;
      const { matches } = await listMatchesForUser(userId, { limit: 15 });
      this.setState({ matches });
    } catch (e) {
      console.log('e', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { matches = [], loading } = this.state;

    if (loading) return <Spinner />;

    return (
      <div className="d-flex flex-column align-items-center">
        {matches.map(({ user: { id, name, images = [] }}) => (
          <div className="mb-4 p-3 bg-white rounded" style={{ border: '0.5px solid #e2e2e2', borderRadius: 20, width: '100%' }}>
            <MediaObject
              text={<Link to={`/admin/users/${id}`}>{name}</Link>}
              subtext={id}
              image={get(images, '[0].url')}
              imageType="circle"
              right={(
                <div className="d-flex">
                  <Link to={`/admin/users/${id}`} className="btn btn-primary mr-3">View</Link>
                  <Button variant="secondary">Edit</Button>
                </div>
              )}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default ListMatches;