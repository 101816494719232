/* External dependencies */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

/* Internal dependencies */
import ListUsers from './ListUsers';
import AuthRoutes from '../AuthRoutes';
import ListPrompts from './ListPrompts';
import { CurrentUserState, getCurrentUser, getCurrentUserLoading } from '../store/ducks/currentUser';
import { ApplicationState } from '../store';

type StateProps = {
  currentUser: CurrentUserState['user'];
  loading: CurrentUserState['loading'];
};

type Props = StateProps;

const ADMIN_USER_IDS = ['user:us-west-2:acc53dc7-1926-450d-84fd-d0f6def783ba'];

class Admin extends React.Component<Props> {
  render() {
    const { currentUser, loading } = this.props;

    if (!currentUser && loading) {
      return null;
    }

    if ((!currentUser && loading === false )|| currentUser && currentUser.id && !ADMIN_USER_IDS.includes(currentUser && currentUser.id || '')) {
      return <Redirect to="/not-found" />
    }

    return (
      <AuthRoutes showNavbar={true} alwaysFillNavbar={true}>
        <div className="container mt-4">
          <Tabs id="tabs">
            <Tab key="users" eventKey="users" title="Users">
              <ListUsers />
            </Tab>
            <Tab key="prompts" eventKey="prompts" title="Prompts">
              <ListPrompts />
            </Tab>
            <Tab key="events" eventKey="events" title="Events">
              {/* <ListUsers /> */}
            </Tab>
          </Tabs>
        </div>
      </AuthRoutes>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
  loading: getCurrentUserLoading(state),
});

export default connect(mapStateToProps)(Admin);