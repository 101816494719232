/* External dependencies */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

/* Internal dependencies */
import Button from '../button/Button';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import TastemakersCampusImage1 from '../assets/images/tastemakers-campus.jpg';
import TastemakersCampusImage2 from '../assets/images/tastemakers-campus-2.png';
import TastemakersCampusImage3 from '../assets/images/tastemakers-campus-3.png';
import TastemakersCampusImage4 from '../assets/images/tastemakers-campus-4.png';
import TastemakersCampusImage5 from '../assets/images/tastemakers-campus-5.png';
import TastemakersCampusImage6 from '../assets/images/tastemakers-campus-6.png';
import TastemakersCampusImage7 from '../assets/images/tastemakers-campus-7.png';
import TastemakersCampusImage8 from '../assets/images/tastemakers-campus-8.png';
import './TastemakersCampus.scss';

const TastemakersCampus = () => {
  const title = 'Beatmatch | Ambassadors | Campus Tastemakers';
  const description = 'Beatmatch Campus Tastemakers is a campus ambassador program created to help connect students through music.';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta property="og:determiner" content="a" />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/ambassadors/tastemakers-campus`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Beatmatch | Ambassadors | Tastemakers Campus" />
        <meta property="og:image" content={`http://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
        {/* <meta property="og:image:secure_url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:width" content="500" /> */}
        <meta property="og:site_name" content="Beatmatch" />
        <meta property="twitter:description" content={description} />
        <meta name="twitter:site" content="@beatmatchsocial" />
        <meta name="twitter:creator" content="@beatmatchsocial" />
        <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}/ambassadors/tastemakers-campus`} />
        <meta property="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:card" content="summary" />
        <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}/ambassadors/tastemakers-campus`} />
        <script type="application/ld+json">
          {
            `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}`}",
              "logo": "${`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.PUBLIC_URL}/logo512.png`}"
            }
          `}
        </script>
      </Helmet>
      <Navbar showLogo={true} alwaysFillNavbar={false} />
      <div className="bm-TastemakersCampus" style={{ marginTop: -75 }}>
        <section className="bm-TastemakersCampus__fullScreenImage">
          <Image
            className="bm-TastemakersCampus__fullScreenImage"
            src={TastemakersCampusImage1}
            width="100%"
            alt="Campus Tastemakers Program Header"
            fluid
          />
          <div className="bm-TastemakersCampus__fullScreenOverlay d-flex align-items-center">
            <div data-aos="fade-in" className="bm-TastemakersCampus__header container">
              <div className="bm-TastemakersCampus__headerContainer">
                <h1 className="mb-2 text-white">REPRESENT YOUR CAMPUS</h1>
                <p className="text-white">
                Want to vibe with music lovers on your campus? Join our exciting campus ambassador program by becoming a Beatmatch Campus Tastemaker.
                </p>
                <Button href="https://beatmatch.typeform.com/to/oKNfELX0" variant="primary" style={{ minWidth: 200, maxWidth: 300, borderRadius: 50, fontWeight: 700, paddingTop: 12 }}>APPLY NOW</Button>
              </div>
            </div>
          </div>
        </section>
        <section className="bm-TastemakersCampus__benefitsHeader d-flex justify-content-center">
          <div style={{ width: '100%' }}>
            <div className="d-flex justify-content-center align-items-center p-4" style={{ width: '100%', backgroundColor: '#000' }}>
              <h1 data-aos="fade-in" className="text-white text-center" style={{ marginBottom: -15 }}>WHY SHOULD YOU</h1>
            </div>
            <div className="d-flex justify-content-center align-items-center p-4 bg-primary" style={{ width: '100%' }}>
              <h1 data-aos="fade-in" className="text-black text-center" style={{ marginBottom: -15, color: '#fff' }}>BE A <span className="text-white" style={{ color: '#000' }}>TASTEMAKER?</span></h1>
            </div>
          </div>
        </section>
        <section>
          <div className="d-flex flex-wrap" style={{ width: '100%' }}>
            <Image
              className="bm-TastemakersCampus__benefitsImage"
              src={TastemakersCampusImage2}
              alt="Campus Tastemakers Program Benefits 1"
              fluid
              style={{
                objectFit: 'cover',
                width: '50%',
                maxHeight: 650,
                overflow: 'hidden',
              }}
            />
            <div className="bm-TastemakersCampus__benefitsDescription d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#000' }}>
              <div data-aos="fade-in" className="text-center p-5" style={{ color: '#fff' }}>
                <h2 className="text-bold text-primary">BUILD YOUR RESUME</h2>
                <p>
                  Don’t let your school year go to waste! Gain marketing experience working with a fast-paced tech startup changing the future of music social.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap flex-row-reverse" style={{ width: '100%' }}>
            <Image
              className="bm-TastemakersCampus__benefitsImage"
              src={TastemakersCampusImage3}
              alt="Campus Tastemakers Program Benefits 2"
              fluid
              style={{
                objectFit: 'cover',
                width: '50%',
                maxHeight: 650,
                overflow: 'hidden',
              }}
            />
            <div className="bm-TastemakersCampus__benefitsDescription d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#000' }}>
              <div data-aos="fade-in" className="text-center p-5" style={{ color: '#fff' }}>
                <h2 className="text-bold text-primary">EARN SOME CASH</h2>
                <p>
                  Make some extra money by collaborating with student organizations, planning events and referring students to download the app.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap" style={{ width: '100%' }}>
            <Image
              className="bm-TastemakersCampus__benefitsImage"
              src={TastemakersCampusImage4}
              alt="Campus Tastemakers Program Benefits 3"
              fluid
              style={{
                objectFit: 'cover',
                width: '50%',
                maxHeight: 650,
                overflow: 'hidden',
              }}
            />
            <div className="bm-TastemakersCampus__benefitsDescription d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#000' }}>
              <div data-aos="fade-in" className="text-center p-5" style={{ color: '#fff' }}>
                <h2 className="text-bold text-primary">GROW WITH US</h2>
                <p>
                Work with a fast-paced tech startup and make a major impact! Campus Tastemakers who excel have an opportunity to become an intern or a full-time employee after graduation.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bm-TastemakersCampus__fullScreenSection">
          <Image
            className="bm-TastemakersCampus__fullScreenImage"
            src={TastemakersCampusImage5}
            width="100%"
            alt="Campus Tastemakers Program Responsibilities"
            fluid
          />
          <div className="bm-TastemakersCampus__fullScreenOverlay d-flex align-items-center">
            <div className="d-flex align-center justify-content-center" style={{ width: '100%' }}>
              <div data-aos="fade-in">
                <h1 className="mb-3 text-white text-center">WHAT DO CAMPUS TASTEMAKERS DO?</h1>
                <div className="d-flex justify-content-center">
                  <Button href="https://beatmatch.typeform.com/to/oKNfELX0" variant="primary" style={{ minWidth: 200, borderRadius: 50, maxWidth: 250, fontWeight: 700, paddingTop: 12 }}>JOIN TASTEMAKERS</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="d-flex flex-wrap" style={{ width: '100%' }}>
            <div className="bm-TastemakersCampus__benefitsDescription d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#000' }}>
              <div data-aos="fade-in" className="text-center p-5" style={{ color: '#fff' }}>
                <h2 className="text-bold text-primary">THROW PARTIES</h2>
                <p>
                Host creative events to attract students to the app. You have the creative freedom to spot local trends, pitch us your ideas, and bring them to life with the help of the Beatmatch team.
                </p>
              </div>
            </div>
            <Image
              className="bm-TastemakersCampus__benefitsImage"
              src={TastemakersCampusImage6}
              alt="Campus Tastemakers Program Responsibilities 1"
              fluid
              style={{
                objectFit: 'cover',
                width: '33.3%',
                maxHeight: 650,
                overflow: 'hidden',
              }}
            />
            <div data-aos="fade-in" className="bm-TastemakersCampus__benefitsDescription d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#000' }}>
              <div className="text-center p-5" style={{ color: '#fff' }}>
                <h2 className="text-bold text-primary">SPREAD OUR MISSION</h2>
                <p>
                Tastemakers represent Beatmatch on campus. Spread Beatmatch’s mission to connect the world through music to your peers.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap" style={{ width: '100%' }}>
          <Image
              className="bm-TastemakersCampus__benefitsImage"
              src={TastemakersCampusImage7}
              alt="Campus Tastemakers Program Responsibilities 2"
              fluid
              style={{
                objectFit: 'cover',
                width: '33.3%',
                maxHeight: 650,
                overflow: 'hidden',
              }}
            />
            <div className="bm-TastemakersCampus__benefitsDescription d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#000' }}>
              <div data-aos="fade-in" className="text-center p-5" style={{ color: '#fff' }}>
                <h2 className="text-bold text-primary">BUILD THE HYPE</h2>
                <p>
                Grow Beatmatch’s presence on your campus. You are the hypeman or hypewoman for your school.
                </p>
              </div>
            </div>
            <Image
              className="bm-TastemakersCampus__benefitsImage"
              src={TastemakersCampusImage8}
              alt="Campus Tastemakers Program Responsibilities 3"
              fluid
              style={{
                objectFit: 'cover',
                width: '33.3%',
                maxHeight: 650,
                overflow: 'hidden',
              }}
            />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TastemakersCampus;