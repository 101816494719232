/* External dependencies */
import React from 'react';

type Step = {
  title?: string;
  content: React.ReactNode;
};

type Props = {
  steps: Step[];
  activeStepIndex: number;
  stepsHeader?: React.ReactNode;
};

const Stepper: React.SFC<Props> = ({ steps, activeStepIndex = 0, stepsHeader }) => {
  return (
    <div className="d-flex flex-column justify-content-center">
      {stepsHeader ? stepsHeader : (
        <div className="d-flex justify-content-center mb-3">
          {steps.map((step, i) => (
            <div key={i} className={`d-flex${i < steps.length - 1 ? ' mr-5' : ''}`} style={{ height: 35 }}>
              <span
                className={`badge badge-${i <= activeStepIndex ? 'primary' : 'secondary'} d-flex justify-content-center align-items-center mr-2`}
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  backgroundColor: i <= activeStepIndex ? undefined : 'rgba(0,0,0,0.38)',
                  color: i <= activeStepIndex ? undefined : '#fff',
                }}
              >
                {i+1}
              </span>
              <h6 style={{ verticalAlign: 'center' }}>{step.title}</h6>
            </div>
          ))}
        </div>
      )}
      <div>
        {steps[activeStepIndex].content}
      </div>
    </div>
  );
};

export default Stepper;
